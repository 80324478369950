import { ADMIN_ROLE } from '@shared/models/user';
import { MenuItem } from '@shared/models/menuItem';

export const headerMenuItems: MenuItem[] = [
  // {
  //   name: 'Главная',
  //   route: 'main',
  // },
  {
    name: 'Договоры',
    route: 'contracts',
  },
  {
    name: 'Образцы',
    route: 'samples',
  },
  {
    name: 'Заказы',
    route: 'orders',
  },
  {
    name: 'Исследования',
    route: 'researches',
  },
  {
    name: 'Администрирование',
    route: 'admin',
  },
  {
    name: 'Отчеты',
    route: 'reports',
    roles: [ADMIN_ROLE]
  },
];
