import { HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { TOKEN_KEY } from "@core/services/storage.service";
import { ContractFilters } from "../models/contract";
import { OrderFilters } from "../models/order";
import { ResearchFilters } from "../models/research";
import { ReportFilters } from "../models/report";
import { SampleFilters } from "../models/sample";
import { ServiceFilters } from "../models/service";

export const getHeadersOption = (isAuth: boolean = true, isFormData: boolean = false): HttpHeaders => new HttpHeaders({
  ...(isAuth ? {
    'Authorization': `Bearer ${window.localStorage.getItem(TOKEN_KEY)}`
  } : {}),
  ...(isFormData ? {
    'Accept': '*/*'
  } : {
    'Content-Type': 'application/json',
  }),
});

export type FilterParams = ContractFilters | ServiceFilters | SampleFilters | OrderFilters | ResearchFilters | ReportFilters;
export const getHttpParams = (params: any): HttpParams => {
  let httpParams: HttpParams = new HttpParams();

  Object.keys(params).forEach(key => {
    const values = params[key];

    if (Array.isArray(values)) {
      values.forEach(item => {
        httpParams = httpParams.append(key, item?.toString());
      });
    } else {
      httpParams = httpParams.append(key, values?.toString());
    }
    
  });
  return httpParams;
};
