import { CommonModule } from "@angular/common";
import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ClrCommonFormsModule } from "@clr/angular";
import { ClrDatepickerModule } from "@clr/angular";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@Component({
  selector: 'date-picker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true,
  imports: [
    ClrDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClrCommonFormsModule,
    NgxMaskDirective,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class DatepickerComponent {
  @Input() label: string;
  @Input() key: string;
  @Input() formGroup: FormGroup;
  @Input() required?: boolean | undefined;

  constructor() {
  }
 
}

