export interface OptionGarItem {
  parentObjectIdArea?: number,
  parentObjectIdRegion?: number,
  nameParentRegion?: string,
  id: number,
  idAddrObj: number,
  objectId: number,
  name: string,
  typeName: string,
  level: number,
  isActual: boolean,
  isActive: boolean,
  isActiveAh: boolean
}

export const noOptions: OptionGarItem[] = [
  {
      id: 55,
      idAddrObj: 1729288,
      objectId: 1405113,
      name: "Москва",
      typeName: "г",
      level: 1,
      isActual: true,
      isActive: true,
      isActiveAh: true
  },
  {
      id: 73,
      idAddrObj: 1741466,
      objectId: 1414662,
      name: "Санкт-Петербург",
      typeName: "г",
      level: 1,
      isActual: true,
      isActive: true,
      isActiveAh: true
  },
  {
    parentObjectIdArea: 465294,
    parentObjectIdRegion: 381755,
    nameParentRegion: "Приморский край",
    id: 23899,
    idAddrObj: 466937,
    objectId: 383163,
    name: "Владивосток",
    typeName: "г",
    level: 5,
    isActual: true,
    isActive: true,
    isActiveAh: true
  },
  {
    parentObjectIdArea: 206152,
    parentObjectIdRegion: 169363,
    nameParentRegion: "Татарстан Респ",
    id: 25496,
    idAddrObj: 206201,
    objectId: 169398,
    name: "Казань",
    typeName: "г",
    level: 5,
    isActual: true,
    isActive: true,
    isActiveAh: true
  }
]