import { CommonModule } from "@angular/common";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import { ClrCheckboxModule, ClrIconModule, ClrModalModule } from "@clr/angular";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";

import { debounceTime, startWith, Subject, takeUntil } from "rxjs";

import { noOptions, OptionGarItem } from "@shared/models/option";
import { getLocalityName, PlanStreet, Locality, getStreetAddress } from "@shared/models/gar";
import { ContragentType, Counterparty, CounterpartyInput, CounterpartyType } from "@shared/models/counterparty";

import { GarService } from "@core/services/gar.service";

import { LEGAL_TYPE, PHYSICAL_TYPE } from "@constants/counterparty";

import { ButtonComponent} from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { CheckboxComponent } from "@core/components/input/checkbox/checkbox.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";
import { CounterpartyService } from "@modules/admin/services/counterparty.service";

@Component({
  selector: 'app-counterparty',
  templateUrl: './counterparty.component.html',
  styleUrls: ['./counterparty.component.scss'],
  imports: [
    TextFieldComponent,
    ClrIconModule,
    ClrModalModule,
    AutocompleteComponent,
    SelectComponent,
    DatepickerComponent,
    ClrCheckboxModule,
    FormsModule,
    ButtonComponent,
    CommonModule,
    CheckboxComponent,
    ReactiveFormsModule
  ],
  standalone: true
})

export class CounterpartyComponent {
  @Input() handleClose: () => void;
  @Input() types?: CounterpartyType[];
  @Input() counterparties: Counterparty[];
  @Input() selectCounterparty: Counterparty | null;
  @Input() addOrUpdateCounterparty?: (counterparty: Counterparty) => void
  @Input() addOrUpdateItem?: (counterparties: Counterparty[], counterparty: Counterparty) => void

  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() createCounterparty: EventEmitter<Counterparty> = new EventEmitter<Counterparty>();


  isOpen: boolean = true;
  localities: Locality[] | OptionGarItem[] = noOptions;
  planStreets: PlanStreet[];

  private readonly destroy$ = new Subject<void>();
  protected readonly ContragentType = ContragentType;
  protected readonly getLocalityName = getLocalityName;
  protected readonly getStreetAddress = getStreetAddress;

  private searchLocalitiesSubject: Subject<string> = new Subject<string>();
  private searchPlanStreetsSubject: Subject<string> = new Subject<string>();

  type: string = PHYSICAL_TYPE;
  loadingLocalities: boolean = false;
  loadingPlanStreets: boolean = false;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private garService: GarService,
    private counterpartyService: CounterpartyService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.setupFormSubscriptions();
    this.initializeDataStreams();
  }

  private initializeDataStreams(): void {
    this.getLocalities();
    if(!this.types){
      this.getTypes();
    }
  }

  getLocalities(): void {
    this.loadingLocalities = true;
    this.garService.getLocalities('').subscribe({
      next: (response: Locality[]): void => {
        this.localities = Object.values(response)
        this.loadingLocalities = false;
      },
      error: (): void => {
        this.loadingLocalities = false;
      }
    });
  }

  getPlanStreets(): void {
    this.loadingPlanStreets = true;
    const parentObjectId = this.type == 'legal' ? this.form.value?.legalAddressLocality?.objectId : this.form.value?.physicalAddressLocality?.objectId;
    if (parentObjectId) {
      this.garService.getPlanStreets('', parentObjectId).subscribe({
        next: (response: PlanStreet[]): void => {
          this.planStreets = Object.values(response)
          .map((pS: PlanStreet) => {
            pS.fullName = getStreetAddress(pS);
            return pS
          })
          this.loadingPlanStreets = false;
        },
        error: (): void => {
          this.loadingPlanStreets = false;
        }
      });
    }
  }

  private setupFormSubscriptions(): void {
    const searchSubjects = {
      'localities': this.searchLocalitiesSubject,
      'planStreets': this.searchPlanStreetsSubject,
    };
    Object.entries(searchSubjects).forEach(([key, subject]) => {
      subject.pipe(
        debounceTime(900),
        takeUntil(this.destroy$)
      ).subscribe(searchTerm => {
          this.loadDataByKey(key, searchTerm);
      });
    });
  }

  private loadDataByKey(key: string, searchTerm: string): void {
    switch (key) {
      case 'localities':
        this.garService.getLocalities(searchTerm).subscribe((response: Locality[]) =>  this.localities = Object.values(response));
        break;
      case 'planStreets':
        const parentObjectId = this.type == 'legal' ? this.form.value?.legalAddressLocality?.objectId : this.form.value?.physicalAddressLocality?.objectId;
        this.garService.getPlanStreets(searchTerm, parentObjectId)
          .subscribe((response: PlanStreet[]) => this.planStreets =  Object.values(response)
          .map((pS: PlanStreet) => {
          pS.fullName = getStreetAddress(pS);
          return pS
        }));
        break;
      default:
        console.warn(`Неизвестный ключ поиска: ${key}`);
    }
  }

  private handleControlTypeValue(typeId: number) {

    if (typeId) {
      this.form.get('inn')?.clearValidators();
      this.form.get('kpp')?.clearValidators();

      if (typeId === ContragentType.LEGAL) {
        this.form.get('inn')?.setValidators([Validators.required]);
        this.form.get('kpp')?.setValidators([Validators.required]);
      } else {
        this.form.get('inn')?.clearValidators();
      }

      this.form.get('inn')?.updateValueAndValidity();
      this.form.get('kpp')?.updateValueAndValidity();
    }
  }

  private setupAddressFormSubscriptions(localityControlName: string, planStreetControlName: string): void {
    this.form.controls[localityControlName].valueChanges.pipe(
      startWith(this.form.controls[localityControlName].value),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      const control = this.form.controls[planStreetControlName];
      value && value.id ? control.enable() : control.disable();
    });
  }

  private initializeForm(): void {
    
    this.form = this.fb.group({
      name: [this.selectCounterparty?.name, Validators.required],
      phone: [this.selectCounterparty?.phone],
      type: [this.selectCounterparty?.type, Validators.required],
      inn: [this.selectCounterparty?.inn],
      kpp: [this.selectCounterparty?.kpp],
      email: [this.selectCounterparty?.email],
      isPerformer: [this.selectCounterparty?.isPerformer ?? false],
      physicalAddressLocality: [this.selectCounterparty?.physicalAddressLocality],
      physicalAddressPlanStreet: [{...this.selectCounterparty?.physicalAddressPlanStreet, fullName: (this.selectCounterparty?.physicalAddressPlanStreet ? getStreetAddress(this.selectCounterparty.physicalAddressPlanStreet) : null)}],
      physicalAddressLocalityIdAddrObj: [this.selectCounterparty?.physicalAddressLocalityIdAddrObj],
      physicalAddressPlanStreetIdAddrObj: [this.selectCounterparty?.physicalAddressPlanStreetIdAddrObj],
      physicalAddressHouseNumber: [this.selectCounterparty?.physicalAddressHouseNumber],
      physicalAddressBuildingNumber: [this.selectCounterparty?.physicalAddressBuildingNumber],
      physicalAddressOfficeNumber: [this.selectCounterparty?.physicalAddressOfficeNumber],
      legalAddressLocality: [this.selectCounterparty?.legalAddressLocality],
      legalAddressPlanStreet: [{...this.selectCounterparty?.legalAddressPlanStreet, fullName: (this.selectCounterparty?.legalAddressPlanStreet ? getStreetAddress(this.selectCounterparty.legalAddressPlanStreet) : null)}],
      legalAddressLocalityIdAddrObj: [this.selectCounterparty?.legalAddressLocalityIdAddrObj],
      legalAddressPlanStreetIdAddrObj: [this.selectCounterparty?.legalAddressPlanStreetIdAddrObj],
      legalAddressHouseNumber: [this.selectCounterparty?.legalAddressHouseNumber],
      legalAddressBuildingNumber: [this.selectCounterparty?.legalAddressBuildingNumber],
      legalAddressOfficeNumber: [this.selectCounterparty?.legalAddressOfficeNumber],
      isDeleted: [this.selectCounterparty?.isDeleted ?? false],
      legalAddressIsCopyFromPhysical: [false],
    });
    
    this.form.get('type')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.handleControlTypeValue(value?.id);
    });
 
    this.setupAddressFormSubscriptions('legalAddressLocality', 'legalAddressPlanStreet');
    this.setupAddressFormSubscriptions('physicalAddressLocality', 'physicalAddressPlanStreet');

    this.setupAddressValueChanges('physicalAddressLocality', ['physicalAddressPlanStreet'], true);
    this.setupAddressValueChanges('physicalAddressPlanStreet', ['physicalAddressOfficeNumber', 'physicalAddressBuildingNumber', 'physicalAddressHouseNumber']);
    this.setupAddressValueChanges('legalAddressLocality', ['legalAddressPlanStreet'], true);
    this.setupAddressValueChanges('legalAddressPlanStreet', ['legalAddressHouseNumber', 'legalAddressBuildingNumber', 'legalAddressOfficeNumber']);
  }

  private setupAddressValueChanges(sourceKey: string, targetKeys: string[], resetObject: boolean = false): void {
    this.form.get(sourceKey)?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      targetKeys.forEach(key => {
        const newValue = key.endsWith('Street') && resetObject ? null : '';
        this.form.get(key)?.setValue(newValue);
      });
    });
  }

  getTypes(): void {
    this.counterpartyService.getTypes().subscribe({
      next: (data: CounterpartyType[]): void => {
        this.types = Object.values(data);
      },
      error: (): void => {
      }
    });
  }
  
  onSearchPhysicalLocality = (input: string): void => {
    !input && this.form.controls['physicalAddressPlanStreet'].reset(null);
    this.form.value.physicalAddressLocality = null;
    this.onSearchLocalities(input, PHYSICAL_TYPE);
  }

  onSearchLegalLocality = (input: string): void => {
    !input && this.form.controls['legalAddressPlanStreet'].reset(null);
    this.form.value.legalAddressLocality = null;
    this.onSearchLocalities(input, LEGAL_TYPE);
  }

  onSearchPhysicalPlanStreets = (input: string): void => {
    this.onSearchPlanStreets(input, PHYSICAL_TYPE);
  }

  onSearchLegalPlanStreets = (input: string): void => {
    this.onSearchPlanStreets(input, LEGAL_TYPE);
  }

  onSearchLocalities(input: string, type: string): void {
    this.type = type;
    this.searchLocalitiesSubject.next(input);
  }

  onSearchPlanStreets(input: string, type: string): void {
    this.type = type;
    this.searchPlanStreetsSubject.next(input);
  }

  handleCloseButton(): void {
    this.handleClose();
    this.selectCounterparty = null;
    this.isOpen = false;
    this.closeButtonClicked.emit();
  }

  async handleSaveButton(): Promise<void> {
    if (this.form.value.legalAddressIsCopyFromPhysical) {
      this.setLegalAddressFromPhysicalAddress();
    }
    const data: CounterpartyInput = {
      name: this.form.value.name,
      email: this.form.value.email,
      inn: this.form.value.inn,
      kpp: this.form.value.kpp,
      phone: this.form.value.phone,
      isPerformer: this.form.value.isPerformer,
      legalAddressBuildingNumber: this.form.value.legalAddressBuildingNumber,
      legalAddressHouseNumber: this.form.value.legalAddressHouseNumber,
      legalAddressLocalityId: this.form.value.legalAddressLocality?.id ?? null,
      legalAddressLocalityIdAddrObj: this.form.value.legalAddressLocality?.idAddrObj ?? null,
      legalAddressOfficeNumber: this.form.value.legalAddressOfficeNumber,
      legalAddressPlanStreetId: this.form.value.legalAddressPlanStreet?.id ?? null,
      legalAddressPlanStreetIdAddrObj: this.form.value.legalAddressPlanStreet?.idAddrObj ?? null,
      physicalAddressBuildingNumber: this.form.value.physicalAddressBuildingNumber,
      physicalAddressHouseNumber: this.form.value.physicalAddressHouseNumber,
      physicalAddressLocalityId: this.form.value.physicalAddressLocality?.id ?? null,
      physicalAddressLocalityIdAddrObj: this.form.value.physicalAddressLocality?.idAddrObj ?? null,
      physicalAddressOfficeNumber: this.form.value.physicalAddressOfficeNumber,
      physicalAddressPlanStreetId: this.form.value.physicalAddressPlanStreet?.id ?? null,
      physicalAddressPlanStreetIdAddrObj: this.form.value.physicalAddressPlanStreet?.idAddrObj ?? null,
      typeId: this.form.value.type.id,
      ...(this.selectCounterparty ? {
        id: this.selectCounterparty?.id ?? null
      } : {})
    };
    (this.selectCounterparty ? this.counterpartyService.update(data) : this.counterpartyService.create(data)).subscribe({
      next: (res: Counterparty): void => {
        this.addOrUpdateCounterparty ? this.addOrUpdateCounterparty(res) : null;
        this.addOrUpdateItem ? this.addOrUpdateItem(this.counterparties, res) : null;
        this.handleClose();
        this.closeButtonClicked.emit();
        this.createCounterparty.emit(res);
      },
      error: (): void => {
      }
    });
  }

  setLegalAddressFromPhysicalAddress = (): void => {
    this.form.patchValue({
      legalAddressLocality: this.form.value.physicalAddressLocality,
      legalAddressPlanStreet: this.form.value.physicalAddressPlanStreet,
      legalAddressHouseNumber: this.form.value.physicalAddressHouseNumber,
      legalAddressBuildingNumber: this.form.value.physicalAddressBuildingNumber,
      legalAddressOfficeNumber: this.form.value.physicalAddressOfficeNumber
    });
  }

}
