import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeadersOption } from "@shared/helpers/headers";
import { PlanStreet } from "@shared/models/gar";
import { Locality } from "@shared/models/gar";

@Injectable({
  providedIn: 'root',
})
export class GarService {
  constructor(private http: HttpClient) {
  }

  getLocalities(search: string): Observable<Locality[]> {
    return this.http.get<Locality[]>(
      environment.apiUrl + `/api/gar/view-localities?name=${search}&isActual=true&isActive=true&isActiveAh=true&limit=20`,
      {
        headers: getHeadersOption()
      }
    );
  }

  getPlanStreets(search: string, parentObjectId: number): Observable<PlanStreet[]> {
    return this.http.get<PlanStreet[]>(
      environment.apiUrl + `/api/gar/plans-streets?name=${search}&parentObjectId=${parentObjectId}&isActual=true&isActive=true&isActiveAh=true&limit=20`,
      {
        headers: getHeadersOption()
      }
    );
  }
}
