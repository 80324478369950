import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { getHeadersOption, getHttpParams } from "@shared/helpers/headers";

import { Status } from "@shared/models/status";
import { Filter } from '@shared/models/filter';
import { Subdivision } from '@shared/models/subdivision';
import { Order, OrderInput, OrdersResponse, OrderType } from "@shared/models/order";
import { MicrosatsResponse, OrderFilters, OrderResearch } from "@shared/models/order";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) {
  }

  get(filters: OrderFilters): Observable<OrdersResponse> {
    return this.http.get<OrdersResponse>(environment.apiUrl + '/api/orders', {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getById(id: number): Observable<Order> {
    return this.http.get<Order>(environment.apiUrl + `/api/orders/${id}`, {
      headers: getHeadersOption()
    });
  }

  getOrderResearches(id: number, filters: OrderFilters): Observable<OrderResearch> {
    return this.http.get<OrderResearch>(environment.apiUrl + `/api/orders/${id}/researches`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }


  create(data: OrderInput): Observable<Order> {
    return this.http.post<Order>(environment.apiUrl + '/api/orders', data, {
      headers: getHeadersOption()
    });
  }

  update(data: OrderInput): Observable<Order> {
    return this.http.patch<Order>(environment.apiUrl + '/api/orders', data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/orders/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/orders/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(environment.apiUrl + '/api/orders/statuses', {
      headers: getHeadersOption()
    });
  }

  getTypes(): Observable<OrderType[]> {
    return this.http.get<OrderType[]>(environment.apiUrl + '/api/orders/types', {
      headers: getHeadersOption()
    });
  }

  getMicrosat(orderId: number, filters: Filter): Observable<MicrosatsResponse> {
    return this.http.get<MicrosatsResponse>(environment.apiUrl + `/api/orders/${orderId}/microsatellites`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getSubdivisionsByTypeId(typeId?: number): Observable<Subdivision[]> {
    return this.http.get<Subdivision[]>(environment.apiUrl + `/api/orders/types/subdivisions/${typeId}`, {
      headers: getHeadersOption()
    });
  }

  importMsp(id: number, formData: FormData): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/microsatellites/upload?orderId=${id}`, formData, {
      headers: getHeadersOption(true, true)
    });
  }

  import(contractId: number, contragentId: number, typeSubdivisionId: number, orderNumber: string, formData: FormData): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/orders/upload?contractId=${contractId}&contragentId=${contragentId}&typeSubdivisionId=${typeSubdivisionId}&orderNumber=${orderNumber}`, formData, {
      headers: getHeadersOption(true, true)
    });
  }

  createAncestryConclusion(orderId: number, data: null): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/orders/${orderId}/conclusion/ancestry`, data, {
      headers: getHeadersOption(true, true)
    });
  }

  createMonogemeConclusion(orderId: number, data: null): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/orders/${orderId}/conclusion/monogeme`, data, {
      headers: getHeadersOption(true, true)
    });
  }


  create1CBill(orderId: number, data: null):Observable<any> {
    return this.http.put<any>(environment.apiUrl + `/api/1c/orders/${orderId}`, data,  {
      headers: getHeadersOption()
    });
  }

}
