import { Component, Input } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ClrDatagridModule, ClrLoadingModule } from '@clr/angular';
import { CommonModule, registerLocaleData } from '@angular/common';

import localeRu from '@angular/common/locales/ru';

import { Entity } from '@shared/models/entity';
import { HistoryData, History } from '@shared/models/history';
import { EntityService } from '@core/services/entity.service';
import { HistoryService } from '@core/services/history.service';
import { translateToRusParam, translateValue } from '@shared/helpers/auditTranslator';


registerLocaleData(localeRu);

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ClrLoadingModule,
    ClrDatagridModule
  ]
})
export class HistoryComponent {
  @Input() id: number;
  @Input() ENTITY_NAME: string;
  @Input() title?: string;
  @Input() link?: string;


  history: History|any;
  entities: Entity[];
  loading: boolean = false;
  entity: Entity | undefined;

  constructor(
    private route: ActivatedRoute,
    private entityService: EntityService,
    private historyService: HistoryService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      if (idParam !== null) {
        this.id = +idParam;
        this.getEntities();
      }
    });
  }

  getEntities = (): void => {
    this.entityService.entities().subscribe({
      next: (data: Entity[]): void => {
        this.entities = Object.values(data);
        this.setEntity();
        this.getHistory();
      },
      error: (): void => {}
    });
  }

  getHistory = (): void => {
    this.loading = true;
    if (this.entity && this.id) {
      this.historyService.get(this.entity.id, this.id).subscribe({
        next: (response: HistoryData): void => {
          this.history = response.data;
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });
    }
  }

  setEntity(): void {
    this.entity = this.entities.find((entity: Entity): boolean => entity.entity === this.ENTITY_NAME);
  }

  formatDate(dateString: string): string {
    const userTimezoneOffset: number = new Date().getTimezoneOffset();
    const userOffsetInMilliseconds: number = -userTimezoneOffset * 60 * 1000;

    return new Date(new Date(dateString).getTime() + userOffsetInMilliseconds).toLocaleString()
  }

  protected readonly translateToRusParam = translateToRusParam;
  protected readonly translateValue = translateValue;
}

