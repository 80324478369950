import { CommonModule } from "@angular/common";
import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ClrCommonFormsModule } from "@clr/angular";
import { ClrCheckboxModule } from "@clr/angular";
import { ClrDatepickerModule } from "@clr/angular";

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [
    ClrDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClrCheckboxModule,
    ClrCommonFormsModule
  ]
})
export class CheckboxComponent {
  @Input() label: string;
  @Input() key: string;
  @Input() formGroup: FormGroup;
  @Input() onChange?: () => void

  constructor() {
  }
}

