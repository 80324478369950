import { CommonModule } from "@angular/common";
import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ClrIconModule } from "@clr/angular";
import { ClrComboboxModule } from "@clr/angular";

@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ClrComboboxModule,
    ReactiveFormsModule,
    ClrIconModule
  ]
})
export class SelectComponent {
  @Input() label: string;
  @Input() key: string;
  @Input() options: any;
  @Input() formGroup: FormGroup;
  @Input() renderKey: string = 'name';
  @Input() multi: boolean = false;
  @Input() required?: boolean | undefined;
  @Input() renderOption?: (option: any) => string;

  @Input() class?: string;

  constructor() {
  }
}

