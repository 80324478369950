import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { getHeadersOption } from "@shared/helpers/headers";
import { Document } from "@shared/models/document";

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {
  }

  getFiles(entityId: number, objectId: number, isDeleted: boolean | undefined = undefined): Observable<Document[]> {
    return this.http.get<Document[]>(environment.apiUrl + `/api/entities/documents/${objectId}?entityId=${entityId}&isDeleted=${isDeleted}`, {
      headers: getHeadersOption()
    });
  }

  get(id: number, fileName: string): void {
    this.http.get(environment.apiUrl + `/api/documents/${id}/file`, {
      responseType: 'blob',
      headers: getHeadersOption()
    }).subscribe((blob: Blob): void => {
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
      }, 100);
    });
  }

  create(entityId: number, objectId: number, formData: FormData): Observable<Document[]> {
    return this.http.post<Document[]>(environment.apiUrl + `/api/documents/files/${objectId}?entityId=${entityId}`, formData, {
      headers: getHeadersOption(true, true)
    });
  }

  delete(id: number): Observable<Document> {
    return this.http.delete<Document>(environment.apiUrl + `/api/documents/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<Document> {
    return this.http.patch<Document>(environment.apiUrl + `/api/documents/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }
}
