<clr-combobox-container [formGroup]="formGroup">
  <label class="clr-col-md-3">{{label}}:{{required ? '*' : ''}}</label>
  <clr-combobox class="clr-col-md-9" [class]="class" [clrMulti]="multi" formControlName="{{key}}">
    <ng-container *clrOptionSelected="let selected">
      {{renderOption ? renderOption(selected) : selected.name}}
    </ng-container>
    <clr-options>
      <clr-option *clrOptionItems="let option of options; field: renderKey" [clrValue]="option">
        {{renderOption ? renderOption(option) : option.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
  <clr-control-error>Выберите {{label}} из списка</clr-control-error>
</clr-combobox-container>
