import {Component, EventEmitter, Input, Output} from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { CommonModule, registerLocaleData } from "@angular/common";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators, } from "@angular/forms";
import { ClarityModule, ClrIconModule, ClrModalModule, ClrComboboxModule, ClrCommonFormsModule } from "@clr/angular";

import { debounceTime, Observable, switchMap, Subject, takeUntil } from "rxjs";

import { DocumentService } from "@core/services/document.service";

import { Entity } from "@shared/models/entity";
import { Status } from "@shared/models/status";
import { Document } from "@shared/models/document";
import { Contract, ContractInput, defaultContractStatus } from "@shared/models/contract";
import { CounterpartiesFilters, CounterpartiesResponse, Counterparty } from "@shared/models/counterparty";

import { convertFormat, dateValidator } from "@shared/helpers/date";

import { CounterpartyService } from "../../../admin/services/counterparty.service";

import { ButtonComponent } from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { UploadDocumentComponent } from "@core/components/documents/document.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";
import { MultipleAutocompleteComponent } from "@core/components/input/multiple-autocomplete/multiple-autocomplete.component";
import { CounterpartyComponent } from "../../../admin/components/registers/counterparties/counterparty/counterparty.component";
import { ContractService } from '@modules/contracts/services/contract.service';

registerLocaleData(localeRu);

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ClrIconModule,
    ClarityModule,
    ClrModalModule,
    SelectComponent,
    ButtonComponent,
    ClrComboboxModule,
    TextFieldComponent,
    DatepickerComponent,
    ReactiveFormsModule,
    ClrCommonFormsModule,
    AutocompleteComponent,
    UploadDocumentComponent,
    CounterpartyComponent,
    MultipleAutocompleteComponent
  ],
  standalone: true
})

export class ContractComponent {
  @Input() statuses: Status[];
  @Input() contracts: Contract[];
  @Input() handleClose: () => void;
  @Input() selectContract: Contract | null;
  @Input() addOrUpdateContract?: (contract: Contract) => void;
  @Input() addOrUpdateItem?: (contracts: Contract[], contract: Contract) => void;
  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() createContract: EventEmitter<Contract> = new EventEmitter<Contract>();

  onFilesChange(files: Document[]): void {
    this.files = files
  }

  onFormDataChange(formData: FormData): void {
    this.formData = formData;
  }

  onEntityChange(entity: Entity | undefined): void {
    this.entity = entity
  }

  isOpenContragentModal: boolean = false;

  addOrEditContragentHandler = (isAdd: boolean): void => { 
    this.contragent = isAdd ? null : this.contragent;
    this.isOpenContragentModal = true;
  };

  handleCloseContragent = (): void => {
    this.isOpenContragentModal = false;
    this.contragent =  this.form.get('contragent')?.value;
  };

  handleCreateCounterpartyEvent(data: Counterparty): void {
    this.form.get('contragent')?.setValue(data);
  }

  files: Document[];
  formData: FormData = new FormData();

  form: FormGroup;
  entity: Entity | undefined;
  contragent: Counterparty | null;
  performers: Counterparty[];
  counterparties: Counterparty[];
  
  isOpen: boolean = true;
  loading: boolean = false;
  loadingPerformers: boolean = false;
  loadingCounterparty: boolean = false;

  counterpartiesFilters: CounterpartiesFilters = {
    limit: 10,
    isDeleted: false,
  }

  performersFilters: CounterpartiesFilters = {
    limit: 10,
    isDeleted: false,
    isPerformer: true
  }

  private searchCounterpartiesSubject: Subject<string> = new Subject<string>();
  private searchPerformersSubject: Subject<string> = new Subject<string>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private contractService: ContractService,
    private documentService: DocumentService,
    private counterpartiesService: CounterpartyService,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.setupFormSubscriptions();
    this.initializeDataStreams();
  }

  private initializeDataStreams(): void {
    this.getCounterparties();
    this.getPerformers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      number: [this.selectContract?.number || '', Validators.required],
      status: [this.selectContract?.status || defaultContractStatus, Validators.required],
      contragent: [this.selectContract?.contragent, Validators.required],
      performer: [this.selectContract?.performer || '', Validators.required],
      dateConclusion: [convertFormat(this.selectContract?.dateConclusion) || '', [Validators.required, dateValidator()]],
      dateEnding: [convertFormat(this.selectContract?.dateEnding) || '', dateValidator()],
      isDeleted: [this.selectContract?.isDeleted || false],
      files: [this.files || []],
    });
  
    this.contragent =  this.form.get('contragent')?.value;
  
    this.form.get('contragent')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((contragent) => {
      this.contragent = contragent;
    });
  
  }


  private setupFormSubscriptions(): void {
    const searchSubjects = {
      'performer': this.searchPerformersSubject,
      'counterparty': this.searchCounterpartiesSubject,
    };
    Object.entries(searchSubjects).forEach(([key, subject]) => {
      subject.pipe(
        debounceTime(900),
        takeUntil(this.destroy$)
      ).subscribe(searchTerm => {
          this.loadDataByKey(key, searchTerm);
      });
    });
  }

  private loadDataByKey(key: string, searchTerm: string): void {
    switch (key) {
      case 'performer':
        this.counterpartiesService.get(searchTerm ? {...this.performersFilters, search: searchTerm } : this.performersFilters).subscribe((response: CounterpartiesResponse) =>  this.performers = Object.values(response.data));
        break;
      case 'counterparty':
        this.counterpartiesService.get(searchTerm ? {...this.counterpartiesFilters, search: searchTerm } : this.counterpartiesFilters).subscribe((response: CounterpartiesResponse) => this.counterparties = response.data);
        break;
      default:
        console.warn(`Неизвестный ключ поиска: ${key}`);
    }
  }

  getCounterparties = (): void => {
    this.loadingCounterparty = true;
    this.counterpartiesService.get(this.counterpartiesFilters).subscribe({
      next: ({data}): void => {
        this.counterparties = Object.values(data);
        this.loadingCounterparty = false;
      },
      error: (): void => {
        this.loadingCounterparty = false;
      }
    });
  }

  getPerformers = (): void => {
    this.loadingPerformers = true;
    this.counterpartiesService.get(this.performersFilters).subscribe({
      next: ({data}): void => {
        this.performers = Object.values(data);
        this.loadingPerformers = false;
      },
      error: (): void => {
        this.loadingPerformers = false;
      }
    });
  }

  onSearchPerformer = (input: string) => {
    this.searchPerformersSubject.next(input)
  };

  onSearchCounterparty = (input: string) => {
    this.searchCounterpartiesSubject.next(input)
  };

  async handleSaveButton(): Promise<void> {
    this.loading = true;
    const data: ContractInput = {
      contragentId: this.form.value.contragent.id,
      performerId: this.form.value.performer.id,
      number: this.form.value.number,
      statusId: this.form.value.status.id,
      dateConclusion: `${this.form.value.dateConclusion.split('.').reverse().join('-')}T00:00:00.000Z`,
      dateEnding: this.form.value?.dateEnding?.length ? `${this.form.value.dateEnding.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      ...(this.selectContract ? {id: this.selectContract?.id ?? null} : {})
    };

    let saveObservable: Observable<any>;

    if (this.selectContract && this.entity) {
      saveObservable = this.contractService.update(data).pipe(
        switchMap((contract: Contract) => {
          this.selectContract = contract;
          this.addOrUpdateContract ? this.addOrUpdateContract(contract) : null;
          this.addOrUpdateItem ? this.addOrUpdateItem(this.contracts, contract) : null;
          this.createContract.emit(contract);
          return this.documentService.create(this.entity!.id, this.selectContract!.id, this.formData);
        })
      );
    } else {
      saveObservable = this.contractService.create(data).pipe(
        switchMap((contract: Contract) => {
          this.selectContract = contract;
          this.addOrUpdateContract ? this.addOrUpdateContract(contract) : null;
          this.addOrUpdateItem ? this.addOrUpdateItem(this.contracts, contract) : null;
          this.createContract.emit(contract);
          return this.documentService.create(this.entity!.id, contract.id, this.formData);
        })
      );
    }

    saveObservable.subscribe({
      next: async (): Promise<void> => {
        this.formData = new FormData;
        this.loading = false;
        this.handleCloseButton();
      },
      error: (): void => {
        this.loading = false;
      }
    });
  }

  handleCloseButton(): void {
    this.handleClose();
    this.selectContract = null;
    this.isOpen = false;
  }

}
