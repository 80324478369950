import { FormControl, FormGroup, FormsModule, Validators, ReactiveFormsModule } from "@angular/forms";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import { Subject, debounceTime } from "rxjs";

import { ClrCheckboxModule, ClrIconModule, ClrModalModule } from "@clr/angular";

import { Filter } from "@shared/models/filter";
import { Nationality } from "@shared/models/nationality";

import { Human, HumanInput } from "@shared/models/human";

import { convertFormat, dateValidator } from "@shared/helpers/date";

import { GENDERS, Gender, translateToEngGender, translateToRusGender } from "@constants/gender";

import { ButtonComponent } from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";
import { HumansService } from "@modules/admin/services/humans.service";
import { DictionariesService } from "@modules/admin/services/dictionaries.service";


registerLocaleData(localeRu);

@Component({
  selector: 'app-human',
  templateUrl: './human.component.html',
  styleUrls: ['./human.component.scss'],
  imports: [
    TextFieldComponent,
    ClrIconModule,
    ClrModalModule,
    AutocompleteComponent,
    SelectComponent,
    DatepickerComponent,
    ClrCheckboxModule,
    FormsModule,
    CommonModule,
    ButtonComponent,
    ReactiveFormsModule
  ],
  standalone: true
})

export class HumanComponent {
  @Input() humans: Human[];
  @Input() handleClose: () => void;
  @Input() selectHuman: Human | null;
  @Input() addOrUpdateItem?: (humans: Human[], human: Human) => void;
  @Output() createHuman: EventEmitter<Human> = new EventEmitter<Human>();
  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  protected readonly GENDERS: Gender[] = GENDERS;

  private readonly debounceTimeMs = 900;
  private searchNationalitySubject: Subject<string> = new Subject<string>();

  form: FormGroup;

  multi: boolean = true;
  isOpen: boolean = true;
  loading: boolean = false;

  nationalities: Nationality[];

  filters: Filter = {
    limit: 10,
    offset: 0
  }
  constructor(private humanService: HumansService,
              private nationalitiesService: DictionariesService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl(this.selectHuman?.firstName),
      middleName: new FormControl(this.selectHuman?.middleName),
      lastName: new FormControl(this.selectHuman?.lastName, Validators.required),
      nationality: new FormControl(this.selectHuman?.nationality),
      email: new FormControl(this.selectHuman?.email),
      birthDate: new FormControl(convertFormat(this.selectHuman?.birthDate), [dateValidator()]),
      gender: new FormControl(this.selectHuman?.gender ? GENDERS.find((gender: Gender): boolean => gender.name === translateToRusGender(this.selectHuman?.gender ?? '')) : null),
      isDeleted: new FormControl(this.selectHuman?.isDeleted ?? false),
    });

    this.searchNationalitySubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue: string): void => {
        if (searchValue.length) {
          this.filters.offset = 0;
          this.filters.search = searchValue;
        } else {
          delete this.filters.search;
        }
        this.getNationalities();
    });

    this.getNationalities();

  }

  onSearchNationality = (input: string) => {
    this.searchNationalitySubject.next(input)
  };

  getNationalities(): void {
    this.loading = true;
    this.nationalitiesService.getNationalities(this.filters).subscribe({
      next: (response): void => {
        this.nationalities = Object.values(response);
        this.loading = false;
      },
      error: (): void => {
        this.loading = false;
      }
    });
  }

  handleCloseButton(): void {
    this.handleClose();
    this.selectHuman = null;
    this.isOpen = false; 
  }

  async handleSaveButton(): Promise<void> {
    const data: HumanInput = {
      firstName: this.form.value.firstName,
      middleName: this.form.value.middleName,
      lastName: this.form.value.lastName,
      nationalityId: this.form.value.nationality?.id,
      email: this.form.value.email,
      ...(this.selectHuman ? {
        id: this.selectHuman.id
      } : {}),
      birthDate: this.form.value.birthDate ? `${this.form.value.birthDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      gender: this.form.value.gender && translateToEngGender(this.form.value.gender.name),
      isDeleted: this.form.value.isDeleted,
    };
    (this.selectHuman ? this.humanService.update(data) : this.humanService.create(data)).subscribe({
      next: (res: Human): void => {
        this.addOrUpdateItem ? this.addOrUpdateItem(this.humans, res) : null;
        this.createHuman.emit(res);
        this.handleClose();
      },
      error: (): void => {
      }
    });
  };

}
