import { CommonModule } from "@angular/common";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ClarityModule, ClrDatagridStateInterface } from "@clr/angular";

import { Order } from "@shared/models/order";
import { Filter } from "@shared/models/filter";
import { Bitrix, BitrixResponse } from "@shared/models/bitrix";
import { Paginate, getPageFilters } from "@shared/models/paginate";

import { convertFormat } from "@shared/helpers/date";
import { DataService } from "@shared/helpers/bitrix.data.service";

import { ButtonComponent } from "@core/components/button/button.component";
import { SearchFieldComponent } from "@core/components/input/search/searchField.component";
import { BitrixService } from "@modules/bitrix/services/bitrix.service";

@Component({
  selector: 'bitrix-list',
  templateUrl: './bitrix-list.component.html',
  styleUrls: ['./bitrix-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClarityModule,
    ButtonComponent,
    SearchFieldComponent
  ]
})

export class BitrixListComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() handleCloseBitrixList: (isOpen: boolean) => void;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected readonly convertFormat = convertFormat;


  constructor(private cdr: ChangeDetectorRef,
              private bitrixService: BitrixService,
              private dataService: DataService
  ) {
  }

  postDataFromResponse: any;

  setPostDataFromResponse(id: number) {
    this.bitrixService.importFromBitrixToCard(id, null).subscribe({
      next: (response: Order) => {
        this.postDataFromResponse = response;
        this.dataService.setPostData(this.postDataFromResponse);
        this.handleCloseBitrixList(false);
        this.isOpenChange.emit(false);
      },
      error: (): void => {
        this.handleCloseBitrixList(true);
        this.isOpenChange.emit(true);
      }
    })
  }

  bitrixData: Bitrix[];

  loading: boolean = false;

  filters: Filter = {
    offset: 0,
    limit: 100
  }

  paginate: Paginate = {
    total: 0,
    totalPages: 0,
  };

  private searchSubject: Subject<string> = new Subject<string>();
  private destroy$ = new Subject<void>();
  private readonly debounceTimeMs = 900;

  async ngOnInit(): Promise<void> {
    this.searchSubject.pipe(
      debounceTime(this.debounceTimeMs),
      takeUntil(this.destroy$)
    ).subscribe((searchValue: string): void => {
      if (searchValue.length) {
        this.filters.number = searchValue.trim();
      } else {
        delete this.filters.number;
      }
      this.importFromBitrix();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearch = (event: Event): void => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.searchSubject.next(target.value);
  }

  importFromBitrix = (): void => {
    this.loading = true;
    this.cdr.detectChanges();
    this.bitrixService.importFromBitrixToList(this.filters).subscribe({
      next: (response: BitrixResponse): void => {
        this.paginate = {
          total: response.total,
          totalPages: response.totalPages,
        };
        this.bitrixData = Object.values(response.data);
        this.loading = false;
        this.isOpen = true;
      },
      error: (): void => {
        this.loading = false;
        this.isOpen = false;
      }
    });
  }

  refresh(state: ClrDatagridStateInterface): void {
    this.filters = {
      ...this.filters,
      ...getPageFilters(state),
    };
    this.importFromBitrix();
  }

  handleCloseButton(): void {
    this.handleCloseBitrixList(false);
    this.isOpenChange.emit(false);
  }

}

