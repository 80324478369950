import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  
  private postDataSubject = new BehaviorSubject<any>(null);
  postData$ = this.postDataSubject.asObservable();

  setPostData(data: any) {
    this.postDataSubject.next(data);
  }

  getPostData() {
    return this.postDataSubject.value;
  }
}
