export interface Locality {
  objectId: number
  parentObjectIdArea: number
  parentObjectIdRegion: number
  name: string
  nameParentArea: string
  nameParentRegion: string
  typeName: string
  level: number
}

export interface PlanStreet {
  id: number
  idAddrObj: number
  objectId: number
  parentObjectId: number
  name: string
  typeName: string
  level: number
  planCode: string
  streetCode: string
  isActual: boolean
  isActive: boolean
  isActiveAh: boolean
  fullName?: string
}

export const getLocalityName = (locality: Locality) => [locality.nameParentArea, locality.nameParentRegion, `${locality.typeName} ${locality.name}`].filter((item: string) => item).join(', ');

export const getLocalityAddress = (locality: Locality) => [locality?.nameParentRegion, locality?.typeName, locality?.name].filter((item: string) => item).join('. ');

export const getStreetAddress = (street: {typeName: string, name: string}) => [street?.typeName, street?.name].filter((item: string) => item).join('. ');

export const getFullAddress = (locality: Locality, street: {typeName: string, name: string}, other: string[]) => `${getLocalityAddress(locality)}, ${getStreetAddress(street)}, ${other.filter((item: string) => item).join(', ')}`;

