<clr-header class="header-6">
  <div class="branding">
    <a href="/contracts" class="nav-link">
      <cds-icon shape="helix"></cds-icon>
      <span class="title">ЛИС &laquo;Геноаналитика&raquo;</span>
    </a>
  </div>
  <div class="header-nav" [clr-nav-level]="1">
    <a *ngFor="let item of filteredMenuItems" routerLink="{{item.route}}" routerLinkActive="active" class="nav-link nav-text">{{item.name}}</a>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
        {{getUser()['lastName']}} {{getUser()['firstName']}}
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a routerLink="user/info" routerLinkActive="active" clrDropdownItem>Сведения</a>
        <!-- <a routerLink="user/settings" routerLinkActive="active" clrDropdownItem>Настройки</a>
        <a routerLink="user/notifications" routerLinkActive="active" clrDropdownItem>Уведомления</a> -->
        <a routerLink="user/logout" routerLinkActive="active"  clrDropdownItem (click)="logout()">Выйти</a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</clr-header>
<router-outlet name="submenu"></router-outlet>
