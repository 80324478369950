import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@pages/main/main.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { roleGuard } from '@core/services/roleGuard.service';

const routes: Routes = [
  { path: "", redirectTo: '/contracts', pathMatch: 'full' },
  { path: "main", component: MainComponent },
  { path: "contracts", loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule) },
  { path: "samples", loadChildren: () => import('./modules/samples/samples.module').then((m) => m.SamplesModule) },
  { path: "orders", loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule) },
  { path: "researches", loadChildren: () => import('./modules/researches/researches.module').then((m) => m.ResearchesModule) },
  { path: "admin", loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule) },
  { path: "user", loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule) },
  { path: "reports", loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule), canActivate: [roleGuard] },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
