import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { getHeadersOption } from "@shared/helpers/headers";
import { HistoryData } from "@shared/models/history";

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private http: HttpClient) {
  }

  get(entityId: number, objectId: number): Observable<HistoryData> {
    return this.http.get<HistoryData>(environment.apiUrl + `/api/audit?entityId=${entityId}&externalId=${objectId}`, {
      headers: getHeadersOption()
    });
  }

}
