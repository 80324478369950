import { convertFormat } from "@shared/helpers/date"
import { Paginate } from "./paginate"

export interface Human {
  id: number
  firstName: string
  lastName: string
  middleName: string
  fullName?: string
  nationality: {
    id: number
    name: string
    isDeleted: boolean
  }
  email: string
  gender: string
  birthDate: string
  isDeleted: boolean
  createdAt: string
}

export interface HumanInput {
  firstName: string
  lastName: string
  middleName?: string
  nationalityId?: number
  email?: string
  gender: string
  birthDate: string | null
  isDeleted: boolean
}

export interface HumansFilters {
  firstName?: string
  search?: string
  orderBy?: string
  isDeleted?: boolean
  offset?: number
  limit?: number
  createdAt?: string
}

export interface HumansResponse extends Paginate {
  data: Human[]
}

export const renderHumanName = (human: Human) => {
  return `${human.lastName} ${human?.firstName ?? ''} ${human?.middleName ?? ''}` + (human.birthDate ? `: ${convertFormat(human.birthDate)}` : '')
};
