import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { getHeadersOption, getHttpParams } from "@shared/helpers/headers";
import { Subdivision, SubdivisionInput, SubdivisionsFilters, SubdivisionsResponse } from '@shared/models/subdivision';

@Injectable({
  providedIn: 'root'
})
export class SubdivisionsService {

  constructor(private http: HttpClient) {
  }

  get(filters: SubdivisionsFilters): Observable<SubdivisionsResponse> {
    return this.http.get<SubdivisionsResponse>(environment.apiUrl + `/api/orders/types/subdivisions`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/orders/types/subdivisions/delete/soft/${id}`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/orders/types/subdivisions/restore/${id}`, {}, {
      headers: getHeadersOption()
    });
  }

  create(data: SubdivisionInput): Observable<Subdivision> {
    return this.http.post<Subdivision>(environment.apiUrl + `/api/orders/types/subdivisions`, data, {
      headers: getHeadersOption()
    });
  }

  update(data: SubdivisionInput): Observable<Subdivision> {
    return this.http.patch<Subdivision>(environment.apiUrl + `/api/orders/types/subdivisions`, data, {
      headers: getHeadersOption()
    });
  }
}
