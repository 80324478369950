<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectHuman ? 'Редактирование' : 'Добавление' }} исследуемого
  </h3>
  <div class="modal-body">
    <form class="horizontal" clrForm [formGroup]="form">
      <text-field [formGroup]="form" type="text" key="lastName" label="Фамилия" [required]="true"></text-field>
      <text-field [formGroup]="form" type="text" key="firstName" label="Имя"></text-field>
      <text-field [formGroup]="form" type="text" key="middleName" label="Отчество"></text-field>
      <custom-select [formGroup]="form" key="gender" label="Пол" [options]="GENDERS" [class]="'custom-combobox-width-95'"></custom-select>
      <autocomplete key="nationality"
                    [formGroup]="form"
                    [loading]="loading"
                    label="Национальность"
                    [options]="nationalities"
                    [onInput]="onSearchNationality"
                    [class]="'custom-combobox-width-95'"
      ></autocomplete>
      <text-field [formGroup]="form" type="email" key="email" label="Почта"></text-field>
      <date-picker label="Дата рождения" key="birthDate" [formGroup]="form"></date-picker>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">Сохранить</button>
  </div>
</clr-modal>
