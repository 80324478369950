<div class="login-wrapper">
  <div class="login">
    <div class="login-body">
      <section class="title" [ngStyle]="{'text-align': 'center'}">ЛИС &laquo;Геноаналитика&raquo;</section>
      <form class="login-group" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
        <clr-input-container>
          <label>Имя пользователя</label>
          <input type="text" name="username" autocomplete="username" clrInput placeholder="Имя пользователя" [(ngModel)]="form.username" required #username="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && username.errors }" />
        </clr-input-container>
        <clr-password-container>
          <label>Пароль</label>
          <input type="password" name="password" autocomplete="current-password" clrPassword placeholder="Пароль" [(ngModel)]="form.password" required minlength="6"
            #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.errors }" />
        </clr-password-container>
        <clr-checkbox-wrapper>
          <label>Запомнить меня</label>
          <input type="checkbox" name="rememberMe" clrCheckbox />
        </clr-checkbox-wrapper>
        <div *ngIf="username.errors && f.submitted || password.errors && f.submitted" class="invalid-feedback" class="error active">Неверное имя пользователя или пароль</div>
        <div *ngIf="f.submitted && isLoginFailed" class="invalid-feedback" class="error active">Ошибка авторизации: {{ error.error.error.message }}</div>
        <button type="submit" class="btn btn-primary">Войти</button>
      </form>
    </div>
  </div>
</div>
