<clr-alert [clrAlertType]="clrAlertType" [clrAlertClosable]="false" *ngIf="errorArray.length" class="error">
  <div cds-layout="horizontal gap:md align:right">
    <cds-icon shape="window-close" (click)="handleClose()"></cds-icon>
  </div>
    
  <ng-container>
    <clr-alert-item cds-layout="vertical gap:md">
      <span class="alert-text" *ngFor="let errorMessage of errorArray" [innerHTML]="errorMessage"></span>
    </clr-alert-item>
  </ng-container>
</clr-alert>