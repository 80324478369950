import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  message$ = new Subject<Message>();

  handle(message: Message) {
    this.message$.next(message);
  }

  clear() {
    this.message$.next({ type: 'clear'});
  }
}

export interface Message {
  type: 'error' | 'message' | 'clear';
  content?: string;
}
