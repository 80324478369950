import { Injectable } from '@angular/core';

import { Filter } from '@shared/models/filter';

import { UsersService } from '@modules/admin/services/users.service';
import { OrderService } from '@modules/orders/services/order.service';
import { AnimalsService } from '@modules/admin/services/animals.service';
import { SampleService } from '@modules/samples/services/sample.service';
import { DoctorsService } from '@modules/admin/services/doctors.service';
import { ContractService } from '@modules/contracts/services/contract.service';
import { ResearchService } from '@modules/researches/services/research.service';
import { DictionariesService } from '@modules/admin/services/dictionaries.service';
import { CounterpartyService } from '@modules/admin/services/counterparty.service';
import { SubdivisionsService } from '@modules/admin/services/subdivisions.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private userService: UsersService,
    private orderService: OrderService,
    private sampleService: SampleService,
    private animalsService: AnimalsService,
    private doctorsService: DoctorsService,
    private researchService: ResearchService,
    private contractService: ContractService,
    private subdivisionService: SubdivisionsService,
    private dictionariesService: DictionariesService,
    private counterPartiesService: CounterpartyService
  ) {}

  // Методы для получения данных
  getDoctorsFromUsers(filters: Filter) {
    return this.userService.getDoctors(filters);
  }

  getDoctors(filters: Filter) {
    return this.doctorsService.get(filters);
  }

  getServices(filters: Filter) {
    return this.dictionariesService.getServices(filters);
  }

  getResearchStatuses() {
    return this.researchService.getStatuses();
  }

  getOrderStatuses() {
    return this.orderService.getStatuses();
  }

  getOrderTypes() {
    return this.orderService.getTypes();
  }

  getOrders(filters: Filter) {
    return this.orderService.get(filters);
  }

  getCounterParties(filters: Filter) {
    return this.counterPartiesService.get(filters);
  }

  getContracts(filters: Filter) {
    return this.contractService.get(filters);
  }

  getSamples(filters: Filter) {
    return this.sampleService.get(filters);
  }

  getSampleById(id: number) {
    return this.sampleService.getById(id);
  }

  getSubdivisionsByTypeId(filters: Filter) {
    return this.subdivisionService.get(filters)
  }

  getAnimals(filters: Filter) {
    return this.animalsService.get(filters);
  }
}
