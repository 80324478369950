import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { ClrIconModule } from "@clr/angular";

@Component({
  selector: 'app-search',
  templateUrl: './searchField.component.html',
  styleUrls: ['./searchField.component.scss'],
  standalone: true,
  imports: [
    ClrIconModule
  ]
})
export class SearchFieldComponent {
  @Input() onSearch: (event: Event) => void;
  @Input() placeholder: string;

  constructor() {
  }
}

