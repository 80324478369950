<div title *ngIf="title"><a [routerLink]="link">{{title}}</a></div>
<clr-datagrid [clrLoading]="loading">
  <clr-dg-column>
    <ng-container>Пользователь</ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container>Дата и время</ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container>Действие</ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let h of history" [clrDgItem]="h">
    <clr-dg-cell
    >{{ h.user.lastName }} {{ h.user.firstName }}</clr-dg-cell
    >
    <clr-dg-cell>{{ formatDate(h.createdAt) }}</clr-dg-cell>
    <clr-dg-cell>{{h.statement.name}}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-detail *clrIfDetail="let detail">
    <clr-dg-detail-header>{{ detail.statement.name}} {{formatDate(detail.createdAt)}}</clr-dg-detail-header>
    <clr-dg-detail-body>
      <clr-datagrid>
        <clr-dg-column>
          <ng-container>Параметр</ng-container>
        </clr-dg-column>
        <clr-dg-column>
          <ng-container>Старое значение</ng-container>
        </clr-dg-column>
        <clr-dg-column>
          <ng-container>Новое значение</ng-container>
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let auditEntity of detail.auditEntities" [clrDgItem]="auditEntity">
          <clr-dg-cell>{{ translateToRusParam(auditEntity.property) }} </clr-dg-cell>
          <clr-dg-cell>{{ translateValue(auditEntity?.prevValueView) }}</clr-dg-cell>
          <clr-dg-cell>{{ translateValue(auditEntity?.valueView) }}</clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <br>
    </clr-dg-detail-body>
  </clr-dg-detail>

  <clr-dg-footer>
    <clr-dg-pagination #pagination>
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
        Записей на странице
      </clr-dg-page-size>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} из
      {{ pagination.totalItems }}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>