<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectCounterparty ? 'Редактирование' : 'Добавление' }} контрагента
  </h3>
  <div class="modal-body">
    <form class="clr-form clr-form-horizontal" clrForm [formGroup]="form">

      <custom-select [formGroup]="form" key="type" label="Тип" [options]="types" [class]="'custom-combobox-width-95'" [required]="true"></custom-select>

      <text-field [formGroup]="form" type="text" key="name" label="Наименование/ФИО" [required]="true"></text-field>

      <text-field [formGroup]="form" [maxLength]="18" type="text" key="phone" label="Телефон" prefix="+7 " mask="(000) 000 00 00"></text-field>

      <text-field [formGroup]="form" type="email" key="email" label="Email"></text-field>

      <text-field [formGroup]="form" [maxLength]="12" type="text" key="inn" label="ИНН" [required]="form.value?.type?.id === ContragentType.LEGAL"></text-field>

      <text-field [formGroup]="form" [maxLength]="9" type="text" key="kpp" label="КПП" [required]="form.value?.type?.id === ContragentType.LEGAL"></text-field>

      <checkbox label="Может быть исполнителем" key="isPerformer" [formGroup]="form"></checkbox>

      <div class="divider">
        <label class="clr-control-label clr-col-md-12">Физический адрес</label>
      </div>

      <autocomplete [renderOption]="getLocalityName"
                    [formGroup]="form"
                    [options]="localities"
                    label="Населенный пункт"
                    [loading]="loadingLocalities"
                    key="physicalAddressLocality"
                    [onInput]="onSearchPhysicalLocality"
                    [class]="'custom-combobox-width-95'"
                    ></autocomplete>

      <autocomplete [formGroup]="form"
                    label="Улица"
                    renderKey="fullName"
                    [options]="planStreets"
                    [loading]="loadingPlanStreets"
                    key="physicalAddressPlanStreet"
                    [renderOption]="getStreetAddress"
                    [class]="'custom-combobox-width-95'"
                    [onInput]="onSearchPhysicalPlanStreets"
      ></autocomplete>

      <text-field [formGroup]="form" type="text" key="physicalAddressHouseNumber" label="Дом"></text-field>

      <text-field [formGroup]="form" type="text" key="physicalAddressBuildingNumber" label="Корпус"></text-field>

      <text-field [formGroup]="form" type="text" key="physicalAddressOfficeNumber" label="Квартира"></text-field>

      <div class="divider">
        <label class="clr-control-label clr-col-md-12">Юридический адрес</label>
      </div>

      <checkbox label="Совпадает с физическим" key="legalAddressIsCopyFromPhysical" [formGroup]="form" [onChange]="setLegalAddressFromPhysicalAddress"></checkbox>

      <div *ngIf="!form.value?.legalAddressIsCopyFromPhysical">

        <autocomplete [renderOption]="getLocalityName"
                      [formGroup]="form"
                      [options]="localities"
                      label="Населенный пункт"
                      key="legalAddressLocality"
                      [loading]="loadingLocalities"
                      [onInput]="onSearchLegalLocality"
                      [class]="'custom-combobox-width-95'"
        ></autocomplete>

        <autocomplete [formGroup]="form"
                      label="Улица"
                      renderKey="fullName"
                      [options]="planStreets"
                      key="legalAddressPlanStreet"
                      [loading]="loadingPlanStreets"
                      [renderOption]="getStreetAddress"
                      [onInput]="onSearchLegalPlanStreets"
                      [class]="'custom-combobox-width-95'"
        ></autocomplete>

        <text-field [formGroup]="form" type="text" key="legalAddressHouseNumber" label="Дом"></text-field>

        <text-field [formGroup]="form" type="text" key="legalAddressBuildingNumber" label="Корпус"></text-field>

        <text-field [formGroup]="form" type="text" key="legalAddressOfficeNumber" label="Квартира"></text-field>
      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">Сохранить</button>
  </div>
</clr-modal>
