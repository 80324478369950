import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Human, HumanInput, HumansFilters, HumansResponse } from '@shared/models/human';
import { getHeadersOption, getHttpParams } from "@shared/helpers/headers";


@Injectable({
  providedIn: 'root'
})
export class HumansService {

  constructor(private http: HttpClient) {
  }

  get(filters: HumansFilters): Observable<HumansResponse> {
    return this.http.get<HumansResponse>(environment.apiUrl + `/api/humans`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  create(data: HumanInput): Observable<Human> {
    return this.http.post<Human>(environment.apiUrl + `/api/humans`, data, {
      headers: getHeadersOption()
    });
  }

  update(data: HumanInput): Observable<Human> {
    return this.http.patch<Human>(environment.apiUrl + `/api/humans`, data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/humans/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/humans/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }
}
