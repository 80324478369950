<clr-combobox-container [formGroup]="formGroup">
  <label class="clr-col-md-3">{{label}}:{{required ? '*' : ''}}</label>
  <clr-combobox class="clr-col-md-9 custom-combobox-width"
                [class]="class"
                [clrLoading]="loading"
                [formControlName]="key"
                (clrInputChange)="onInputHandler($event)"
  >
    <clr-options>
      <clr-option *clrOptionItems="let option of options; field: renderKey;" [clrValue]="option">
        {{renderOption ? renderOption(option) : option.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
  <clr-control-error>Выберите {{label}} из списка</clr-control-error>
</clr-combobox-container>
