import { ClrDatagridStateInterface } from "@clr/angular";

import { Animal } from "./animal";
import { Status } from "./status";
import { Locality } from "./gar";
import { Doctor } from "./doctor";
import { Contract } from "./contract";
import { Subdivision } from "./subdivision";
import { Research, ResearchInput } from "./research";
import { Paginate, getFiltersFromDatagrid } from "./paginate";

export enum OrderTypes {
  HUMAN = 1,
  ANIMAL = 2,
  SCIENCE = 3,
}
export interface Order {
  fullName?: string;
  id: number
  number: string
  amount: string
  contract: Contract
  status: Status
  researches: Research[]
  createdAt: string
  deadline: string
  dateOfPayment?: string
  addictionNumber?: string
  type: OrderType
  files: Document[]
  samplesCount: number
  researchesCount: number
  typeSubdivision: Subdivision
  isDeleted: boolean
  
  comment: string,
  diagnosis: string,
  doctorId: number,
  doctor: Doctor,
  paidSum: number,
  localityAddrObjId: number,
  locality: Locality,
  dateOfSamplesArrived: string
  samplesAdmissionOfficeLastDate?: string
}
export interface OrderResearch {
  data: Research[]
  total: number
  totalPages: number
}

export interface OrderType {
  id: number
  name: string
}
export interface Microsatellite {
  id: number
  animalId: number
  animalName: string
  animalInventoryNumber: string
  animalBirthDate: string
  valueId: number
  locusId: number
  locusCode: string
  allele1: number
  allele2: number
}

export interface OrderResearchesInput extends Omit<ResearchInput, 'orderId' | 'result'> {

}

export interface OrderInput {
  id?: number | null
  number: string
  statusId: number
  contractId: number
  typeSubdivisionId?: number
  typeId: number
  researches?: OrderResearchesInput[]
  amount?: string
  deadline: string | null
  isDeleted: boolean
  dateOfSamplesArrived: string | null
  comment: string,
  diagnosis?: string,
  addictionNumber?: string,
  doctorId: number | null,
  paidSum: number,
  dateOfPayment: string | null,
  physicalAddressLocalityIdAddrObj: number,
}

export interface OrderFilters {
  search?: string
  status?: number
  orderBy?: string
  limit?: number
  offset?: number
  typeId?: number
  isDeleted?: boolean
  dateOfPaymentBegin?: string
  dateOfPaymentEnd?: string
  createdAtBegin?: string
  createdAtEnd?: string
  typeSubdivisionName?: string
  dateOfPayment?: string
}

export interface OrdersResponse extends Paginate {
  data: Order[]
}
export interface MicrosatsResponse extends Paginate {
  data: Microsatellite[]
}

export const renderOrderName = (order: Order) => {
  const orderName = `(id ${order.id}) ${order.number ? `: ${order.number}` : ''}`
  return orderName;
}

export const renderAnimalName = (animal: Microsatellite) => {
  return `${animal.animalName ?? ''} ${animal.animalInventoryNumber ? ` (инв. №: ${animal.animalInventoryNumber})` : ''}`;
}

export const getFilters = (state: ClrDatagridStateInterface): OrderFilters => {
  let stageFilters = getFiltersFromDatagrid(state);
  return {
    ...(stageFilters.hasOwnProperty('typeSubdivision.name') ? {
      typeSubdivisionName: stageFilters['typeSubdivision.name']
    } : {}),
   
  }
}
