import { CommonModule } from "@angular/common";
import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ClrLoadingModule } from "@clr/angular";
import { ClrIconModule } from "@clr/angular";
import { ClrComboboxModule } from "@clr/angular";

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ClrComboboxModule,
    ClrIconModule,
    ClrLoadingModule,
    ReactiveFormsModule
  ]
})
export class AutocompleteComponent {
  @Input() label: string;
  @Input() key: string;
  @Input() options: any[];
  @Input() loading: boolean = false;
  @Input() renderKey: string = 'name';
  @Input() renderOption?: (option: any) => string | null;
  @Input() onInput?: (e: string) => void;
  @Input() required?: boolean | undefined;
  @Input() class?: string;

  @Input() formGroup: FormGroup;

  constructor() {
  }

  onInputHandler = (e: string): void => {
    const find = this.options?.find((option: any): boolean => {
      return option[this.renderKey] === e;
    });
    if (this.onInput && !find) {
      this.onInput(e);
    }
  }
}

