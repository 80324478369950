<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false" [clrModalSize]="'xl'">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    Добавление заказа из Битрикс
  </h3>
  <div class="modal-body">
    <div class="fixed">
      <app-search placeholder="Поиск по полному номеру сделки в Битрикс" [onSearch]="onSearch"></app-search>
      <clr-datagrid [clrDgLoading]="loading" (clrDgRefresh)="refresh($event)">
        <clr-dg-column  [clrDgSortBy]="'id'">
          <ng-container>№</ng-container>
        </clr-dg-column>
        <clr-dg-column  [clrDgSortBy]="'number'">
          <ng-container>№ Сделки</ng-container>
        </clr-dg-column>
        <clr-dg-column  [clrDgSortBy]="'number'">
          <ng-container>№ Договора</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'number'">
          <ng-container>Сумма</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'number'">
          <ng-container>Передан</ng-container>
        </clr-dg-column>
        <clr-dg-column  [clrDgSortBy]="'number'">
          <ng-container>Дата создания</ng-container>
        </clr-dg-column>
        <clr-dg-column  [clrDgSortBy]="'number'">
          <ng-container>Подразделение</ng-container>
        </clr-dg-column>
        <clr-dg-row *ngFor="let b of bitrixData" [clrDgItem]="b" (dblclick)="setPostDataFromResponse(b.id)">
          <clr-dg-cell>{{ b.id }}</clr-dg-cell>
          <clr-dg-cell>{{ b.number }}</clr-dg-cell>
          <clr-dg-cell>{{ b.contractNumber }}</clr-dg-cell>
          <clr-dg-cell>{{ b.amount }}</clr-dg-cell>
          <clr-dg-cell>{{ b.isTransferred ? 'Да' : 'Нет' }}</clr-dg-cell>
          <clr-dg-cell>{{ convertFormat(b.createdAt) }}</clr-dg-cell>
          <clr-dg-cell>{{ b.typeSubdivision }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination
            #pagination
            [clrDgPageSize]="100"
            [clrDgTotalItems]="paginate.total"
            [clrDgLastPage]="paginate.totalPages"
          >
            <clr-dg-page-size>
              Записей на странице
            </clr-dg-page-size>
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} из
            {{ paginate.total }}
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>

</clr-modal>
