import {Component, EventEmitter, Input, Output} from "@angular/core";
import { Subject, debounceTime } from "rxjs";
import { CommonModule, registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import { FormControl, FormGroup, FormsModule, Validators, ReactiveFormsModule } from "@angular/forms";

import { ClrCheckboxModule, ClrIconModule, ClrModalModule } from "@clr/angular";

import { convertFormat } from "@shared/helpers/date";

import { ButtonComponent } from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";

import { GarService } from "@core/services/gar.service";
import { OrderService } from "../../../../../orders/services/order.service";

import { Filter } from "@shared/models/filter";
import { OrderType } from "@shared/models/order";
import { Doctor, DoctorInput } from "@shared/models/doctor";
import { OptionGarItem, noOptions } from "@shared/models/option";
import { Locality, getLocalityName } from "@shared/models/gar";
import { DoctorsService } from "@modules/admin/services/doctors.service";

registerLocaleData(localeRu);

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss'],
  imports: [
    ClrIconModule,
    ClrModalModule,
    ClrCheckboxModule,

    FormsModule,
    CommonModule,
    ReactiveFormsModule,

    ButtonComponent,
    SelectComponent,
    TextFieldComponent,
    DatepickerComponent,
    AutocompleteComponent
  ],
  standalone: true
})

export class DoctorComponent {
  @Input() doctors: Doctor[];
  @Input() handleClose: () => void;
  @Input() selectDoctor: Doctor | null;
  @Input() addOrUpdateItem?: (doctors: Doctor[], doctor: Doctor) => void;
  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() createDoctor: EventEmitter<Doctor> = new EventEmitter<Doctor>();

  protected readonly getLocalityName = getLocalityName;

  multi: boolean = true;
  isOpen: boolean = true;
  loading: boolean = false;

  form: FormGroup;
  error: any = null;

  types: OrderType[];
  localities: Locality[] | OptionGarItem[] = noOptions;

  filters: Filter = {
    limit: 10,
    isDeleted: false
  };

  onSearchLocality = (input: string) => this.searchLocalitiesSubject.next(input)

  private searchLocalitiesSubject: Subject<string> = new Subject<string>();

  private readonly debounceTimeMs = 900;

  constructor(
    private garService: GarService,
    private orderService: OrderService,
    private doctorService: DoctorsService,
  ) {}


  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl(this.selectDoctor?.firstName),
      middleName: new FormControl(this.selectDoctor?.middleName),
      lastName: new FormControl(this.selectDoctor?.lastName, Validators.required),
      contractDate: new FormControl(convertFormat(this.selectDoctor?.contractDate)),
      contractNumber: new FormControl(this.selectDoctor?.contractNumber),
      locality: new FormControl(this.selectDoctor?.locality),
      orderTypeId: new FormControl(this.selectDoctor?.orderType),
      isDeleted: new FormControl(this.selectDoctor?.isDeleted ?? false),
    });

    this.getTypes();

    this.searchLocalitiesSubject
    .pipe(debounceTime(this.debounceTimeMs))
    .subscribe((searchValue) => {
      this.loading = true;
      this.garService.getLocalities(searchValue).subscribe({
        next: (data: Locality[]): void => {
          this.localities = Object.values(data);
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });
    });
  }

  createGetItems = <T>(service: any, method: string, itemsProperty: keyof T): void => {
    this.loading = true;
    service[method](this.filters).subscribe({
      next: (response: any): void => {
        (this as Record<keyof T, any>)[itemsProperty] = Object.values(response.data ?? response);
        this.loading = false;
      },
      error: (): void => {
        this.loading = false;
      },
    });
  };

  getTypes = (): void => {
    this.createGetItems<DoctorComponent>(this.orderService, 'getTypes', 'types');
  };

  handleCloseButton(): void {
    this.handleClose();
    this.selectDoctor = null;
    this.isOpen = false;
  }

  async handleSaveButton(): Promise<void> {
    const data: DoctorInput = {
      firstName: this.form.value.firstName,
      middleName: this.form.value.middleName,
      lastName: this.form.value.lastName,
      contractDate: this.form.value?.contractDate?.length ? `${this.form.value.contractDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      contractNumber: this.form.value.contractNumber,
      localityIdAddrObj: this.form.value.locality?.idAddrObj,
      orderTypeId: this.form.value?.orderTypeId?.id,
      ...(this.selectDoctor ? {
        id: this.selectDoctor.id
      } : {}),
      isDeleted: this.form.value.isDeleted ?? false
    };
    (this.selectDoctor ? this.doctorService.update(data) : this.doctorService.create(data)).subscribe({
      next: (res: Doctor): void => {
        this.addOrUpdateItem ? this.addOrUpdateItem(this.doctors, res) : null;
        this.createDoctor.emit(res);
        this.handleClose();
      },
      error: (): void => {
      }
    });
  };
}
