<clr-input-container [formGroup]="formGroup">
  <label class="clr-col-md-3">{{label}}:{{required ? '*' : ''}}</label>
  <input
    clrInput
    type="{{type}}"
    [class]="class ? class : 'width'"
    [readonly]="readonly"
    [minLength]="minLength"
    formControlName="{{key}}"
    class="clr-col-md-9"
    [maxLength]="maxLength ?? '512'"
    [prefix]="prefix ?? ''" [mask]="mask ?? ''"
  />
  <clr-control-error>Поле {{label}} обязательно для заполнения!</clr-control-error>
</clr-input-container>
