import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeadersOption } from "@shared/helpers/headers";
import { Entity } from "@shared/models/entity";

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  constructor(private http: HttpClient) {
  }

  entities(): Observable<Entity[]> {
    return this.http.get<Entity[]>(
      environment.apiUrl + '/api/entities', {
        headers: getHeadersOption()
      }
    );
  }
}
