import { ClrDatagridStateInterface } from "@clr/angular/data/datagrid/interfaces/state.interface";
import { FilterParams } from "../helpers/headers";

export interface Paginate {
  total: number
  totalPages: number
}

export const getFiltersFromDatagrid = (state: ClrDatagridStateInterface):{ [p: string]: string } => {
  let stageFilters: { [prop: string]: string } = {};
  if (state.filters) {
    for (let filter of state.filters) {
      let {property, value} = <{ property: string, value: string }>filter;
      stageFilters[property] = value;
    }
  }
  return stageFilters;
}

export const getPageFilters = (state: ClrDatagridStateInterface): FilterParams => {
  const {sort, page} = state;
  const orderBy = sort?.by ? `${sort.by} ${sort.reverse ? 'DESC' : 'ASC'}` : 'id DESC';
  const from = page!.from! > 0 ? page?.from : 0;

  return {
    limit: page?.size,
    offset: from,
    ...(orderBy.length ? {orderBy} : {}),
  };
};
