import { Component } from '@angular/core';
import { ClrIconModule } from '@clr/angular';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClrIconModule
  ]
})
export class ButtonComponent {  
}

