<div class="card">
  <h3 class="card-header">
    <span class="b-parentTitle" cds-text="caption"><ng-content select="[parentTitle]"></ng-content></span>
    <div class="b-title"><ng-content select="[title]"></ng-content></div>
  </h3>
  <div class="card-block">
    <ng-content></ng-content>
  </div>
</div>

