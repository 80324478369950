import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { getHeadersOption, getHttpParams } from '@shared/helpers/headers';

import { BitrixFilters, BitrixResponse } from '@shared/models/bitrix';

@Injectable({
  providedIn: 'root'
})
export class BitrixService {

  constructor(private http: HttpClient) {
  }

  exportToBitrix(orderId: number, data: null):Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/bitrix/orders/${orderId}`, data,  {
      headers: getHeadersOption()
    });
  }

  importFromBitrixToList(filters: BitrixFilters):Observable<BitrixResponse> {
    return this.http.get<BitrixResponse>(environment.apiUrl + '/api/bitrix/deals',  {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  importFromBitrixToCard(dealId: number, data: null):Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/bitrix/deals/${dealId}/import`, data,  {
      headers: getHeadersOption(),
    });
  }

}