import { CommonModule } from "@angular/common";
import { Input } from "@angular/core";
import { Component } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ClrInputModule } from "@clr/angular";
import { ClrCommonFormsModule } from "@clr/angular";
import { ClrIconModule } from "@clr/angular";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@Component({
  selector: 'text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClrIconModule,
    FormsModule,
    ClrCommonFormsModule,
    ClrInputModule,
    ReactiveFormsModule,
    NgxMaskDirective
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class TextFieldComponent {
  @Input() label: string;
  @Input() key: string;
  @Input() type: string;
  @Input() formGroup: FormGroup;
  @Input() maxLength?: number | null;
  @Input() minLength?: number | null;
  @Input() mask?: any;
  @Input() class?: string;
  @Input() prefix?: string;
  @Input() readonly?: boolean;
  @Input() required: boolean | undefined;
  

  constructor() {
  }
}

