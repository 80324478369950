import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";
import { UserChangePasswordInput, UsersResponse } from "@shared/models/user";
import { UserRole } from "@shared/models/user";
import { UserInput } from "@shared/models/user";
import { UsersFilters } from "@shared/models/user";
import { User } from "@shared/models/user";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) {
  }

  get(filters: UsersFilters): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(environment.apiUrl + `/api/users`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  create(data: UserInput): Observable<User> {
    return this.http.post<User>(environment.apiUrl + `/api/auth/register`, data, {
      headers: getHeadersOption()
    });
  }

  update(id: string, data: UserInput): Observable<User> {
    return this.http.patch<User>(environment.apiUrl + `/api/users/${id}`, data, {
      headers: getHeadersOption()
    });
  }

  changePassword(data: UserChangePasswordInput): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/api/account/password/change`, data, {
      headers: getHeadersOption()
    });
  }

  getRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(environment.apiUrl + `/api/users/roles`, {
      headers: getHeadersOption()
    });
  }

  getDoctors(filters: UsersFilters): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(environment.apiUrl + `/api/users/doctors`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  block(id: string): Observable<User> {
    return this.http.post<User>(environment.apiUrl + `/api/users/${id}/block`, {}, {
      headers: getHeadersOption()
    });
  }

  unblock(id: string): Observable<User> {
    return this.http.post<User>(environment.apiUrl + `/api/users/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }
}
