<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectContract ? 'Редактирование' : 'Добавление' }} договора
  </h3>
  <div class="modal-body">
    <form class="horizontal" clrForm [formGroup]="form">
      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-11">
          <text-field type="text"
                      key="number"
                      [required]="true"
                      [formGroup]="form"
                      label="№ договора"
          ></text-field>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-11">
          <autocomplete [formGroup]="form"
                        key="contragent"
                        label="Заказчик"
                        [required]="true"
                        [options]="counterparties"
                        [loading]="loadingCounterparty"
                        [onInput]="onSearchCounterparty"
                        [class]="'custom-combobox-width-95'"
          ></autocomplete>
        </div>

        <div class="clr-col-md-1" cds-layout="p:none">
          <div class="clr-row">
            <button type="button"
              aria-label="add"
              class="btn btn-link addBtn"
              cds-layout="p-t:xxs m:none m-r:xxs"
              (click)="addOrEditContragentHandler(true)"
              ><clr-icon shape="plus"></clr-icon>
            </button>
            <button type="button"
              aria-label="add"
              [disabled]="!contragent"
              class="btn btn-link addBtn"
              cds-layout="p-t:xxs m:none"
              (click)="addOrEditContragentHandler(false)"
              ><clr-icon shape="pencil"></clr-icon>
            </button>

          </div>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-11">
          <autocomplete [formGroup]="form"
                        key="performer"
                        [required]="true"
                        label="Исполнитель"
                        [options]="performers"
                        [onInput]="onSearchPerformer"
                        [loading]="loadingPerformers"
                        [class]="'custom-combobox-width-95'"
          ></autocomplete>
        </div>
      </div>


      <date-picker label="Дата заключения" key="dateConclusion" [formGroup]="form" [required]="true"></date-picker>

      <date-picker label="Дата окончания" key="dateEnding" [formGroup]="form"></date-picker>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-11">
          <custom-select [formGroup]="form" key="status" label="Статус" [options]="statuses" [class]="'custom-combobox-width-95'"></custom-select>
        </div>
      </div>

      <div class="divider"></div>

      <upload-document [loading]="loading"
                       [ENTITY_NAME]="'Contract'"
                       [selectEntity]="selectContract"
                       (filesChange)="onFilesChange($event)"
                       (entityChange)="onEntityChange($event)"
                       (formDataChange)="onFormDataChange($event)"
      ></upload-document>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">
      Сохранить
    </button>
  </div>
</clr-modal>

<ng-container *ngIf="isOpenContragentModal">
  <app-counterparty
    [counterparties]="counterparties"
    [selectCounterparty]="contragent"
    [handleClose]="handleCloseContragent"
    (closeButtonClicked)="handleCloseContragent()"
    (createCounterparty)="handleCreateCounterpartyEvent($event)"
    ></app-counterparty>

</ng-container>
