<div style=" display: flex; justify-content: center;">
  <div class="clr-row clr-justify-content-between" cds-layout="p-t:md"  style="width: 100%;">
    <div class="clr-col-5" cds-layout="p-l:none">
      <label class="btn btn-outline">
        <input type="file"
              accept="*"
              #fileInput
              class="button-input"
              (change)="addFile($event)"
              placeholder="Прикрепить файл"
        >Прикрепить файл</label>
    </div>

    <div class="clr-col-7" cds-layout="p-r:none" *ngIf="selectOrder">
        <button type="button"
                style="width: 100%;"
                class="btn btn-outline"
                (click)="create1CBill()"
                [disabled]="!selectOrder"
                >Создать счет в 1C
        </button>
    </div>
  </div>
</div>

<div class="clr-row" cds-layout="p-t:md" *ngFor="let file of form.value.files; index as i; ">
  <div class="clr-col-9">
    <span>{{i + 1}}. {{file.name}}  {{file.isDeleted ? '(удален)' : null}}</span>
  </div>
  <div class="clr-col-3">
    <button *ngIf="!file?.isDeleted" class="btn btn-danger" (click)="deleteFile(null, i)">
      <clr-icon shape="close"></clr-icon>
    </button>
    <button *ngIf="file?.isDeleted" class="btn btn-primary" (click)="restoreFile(null, i)">
      <clr-icon shape="refresh"></clr-icon>
    </button>
  </div>
</div>
<div class="clr-row" cds-layout="p-t:md" *ngFor="let file of files; index as i; ">
  <div class="clr-col-9">
    <span>{{i + 1}}. {{file.name}} {{file.isDeleted ? '(удален)' : null}}</span>
  </div>
  <div class="btn-group btn-primary btn-icon">
    <button *ngIf="!file?.isDeleted" class="btn btn-danger" (click)="deleteFile(file.id, i)">
      <clr-icon shape="close"></clr-icon>
      <span class="clr-icon-title">Delete</span>
    </button>
    <button *ngIf="file?.isDeleted" class="btn btn-primary" (click)="restoreFile(file.id, i)">
      <clr-icon shape="refresh"></clr-icon>
      <span class="clr-icon-title">Restore</span>
    </button>
    <button *ngIf="!file?.isDeleted" class="btn btn-primary"(click)="downloadFile($event, file)">
      <clr-icon shape="download"></clr-icon>
      <span class="clr-icon-title">Download</span>
    </button>
  </div>
</div>
