import { Component } from '@angular/core';
import { ClrCommonStringsService } from "@clr/angular";
import { AuthService } from './core/services/auth.service';
import { StorageService } from './core/services/storage.service';
import { ruLocale } from "./shared/locale/ru";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title: string = 'genoanalyrics-lims-frontend';
  isLoggedIn: boolean = false;
  showAdmin: boolean = false;

  constructor(private storageService: StorageService,
              private authService: AuthService,
              commonStrings: ClrCommonStringsService
  ) {
    commonStrings.localize(ruLocale);
  }

  ngOnInit(): void {
    this.isLoggedIn = this.storageService.isLoggedIn();

    if (this.isLoggedIn) {
      this.showAdmin = this.storageService.isAdmin();
    }
  }

  logout(): void {
    this.authService.logout();
  }

}
