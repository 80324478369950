import { Paginate } from './paginate';
import { OrderType } from '@shared/models/order';

export interface Subdivision {
  id: number,
  name: string,
  typeId: number,
  type: OrderType,
  createdAt: string,
  updatedAt: string,
  isDeleted?: boolean,
  userIdCreated: string,
  userIdUpdated: string,
}

export interface SubdivisionInput {
  id?: number,
  name: string,
  typeId: number,
  isDeleted?: boolean
}

export interface SubdivisionsFilters {
  limit?: number
  offset?: number
  search?: string
  typeId?: number
  isDeleted?: boolean
}

export interface SubdivisionsResponse extends Paginate {
  data: Subdivision[]
  error?: {
    code: string,
    message: string
  },
}

export const renderSubdivisionName = (subdivision: Subdivision) => subdivision.name;