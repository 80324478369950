import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";
import { ChangeStatusInput } from "@shared/models/sample";
import { SampleFilters } from "@shared/models/sample";
import { SamplesResponse } from "@shared/models/sample";
import { SampleInput } from "@shared/models/sample";
import { Sample } from "@shared/models/sample";
import { Status } from "@shared/models/status";

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor(private http: HttpClient) {
  }

  get(filters: SampleFilters): Observable<SamplesResponse> {
    return this.http.get<SamplesResponse>(environment.apiUrl + `/api/samples`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getExcel(filters: SampleFilters): Observable<Blob> {
    return this.http.get(environment.apiUrl + '/api/samples/excel', {
      responseType: 'blob',
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getById(id: number): Observable<Sample> {
    return this.http.get<Sample>(environment.apiUrl + `/api/samples/${id}`, {
      headers: getHeadersOption()
    });
  }

  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(environment.apiUrl + '/api/samples/statuses?limit=100', {
      headers: getHeadersOption()
    });
  }


  create(data: SampleInput): Observable<Sample> {
    return this.http.post<Sample>(environment.apiUrl + '/api/samples', data, {
      headers: getHeadersOption()
    });
  }

  update(data: SampleInput): Observable<Sample> {
    return this.http.patch<Sample>(environment.apiUrl + '/api/samples', data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/samples/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/samples/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  changeStatuses(data: ChangeStatusInput): Observable<Sample[]> {
    return this.http.patch<Sample[]>(environment.apiUrl + `/api/samples/status?statusId=${data.statusId}`, {ids: data.ids}, {
      headers: getHeadersOption(),
    });
  }
}
