export interface Gender {
  id: number
  name: string
}

export const GENDERS: Gender[] = [
  {
    id: 0,
    name: 'Мужской',
  },
  {
    id: 1,
    name: 'Женский',
  }
];

export const getGenderName = (gender: Gender): string => gender.name;

export const translateToEngGender = (gender: string): string => {
  return gender ? gender === 'Мужской' ? 'Male' : 'Female' : ''
};

export const translateToRusGender = (gender: string): string => {
  return gender ? gender === 'Male' ? 'Мужской' : 'Женский' : ''
};
