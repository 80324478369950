import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";
import { AnimalsResponse } from "@shared/models/animal";
import { AnimalsFilters } from "@shared/models/animal";
import { Locus } from "@shared/models/animal";
import { MicrosatelliteUpdateInput } from "@shared/models/animal";
import { MicrosatelliteCreateInput } from "@shared/models/animal";
import { Microsatellite } from "@shared/models/animal";
import { AnimalInput } from "@shared/models/animal";
import { Animal } from "@shared/models/animal";

@Injectable({
  providedIn: 'root'
})
export class AnimalsService {

  constructor(private http: HttpClient) {
  }

  get(filters: AnimalsFilters): Observable<AnimalsResponse> {
    return this.http.get<AnimalsResponse>(environment.apiUrl + `/api/animals`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getById(id: number): Observable<Animal> {
    return this.http.get<Animal>(environment.apiUrl + `/api/animals/${id}`, {
      headers: getHeadersOption()
    });
  }

  create(data: AnimalInput): Observable<Animal> {
    return this.http.post<Animal>(environment.apiUrl + `/api/animals`, data, {
      headers: getHeadersOption()
    });
  }

  update(data: AnimalInput): Observable<Animal> {
    return this.http.patch<Animal>(environment.apiUrl + `/api/animals`, data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/animals/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/animals/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  getLocuses(): Observable<Locus[]> {
    return this.http.get<Locus[]>(environment.apiUrl + `/api/locuses`, {
      headers: getHeadersOption()
    });
  }

  getMicrosatellites(id: number): Observable<Microsatellite> {
    return this.http.get<Microsatellite>(environment.apiUrl + `/api/microsatellites?animalId=${id}`, {
      headers: getHeadersOption()
    });
  }

  updateMicrosatellites(data: MicrosatelliteUpdateInput): Observable<Microsatellite> {
    return this.http.patch<Microsatellite>(environment.apiUrl + `/api/microsatellites`, data, {
      headers: getHeadersOption()
    });
  }

  createMicrosatellites(data: MicrosatelliteCreateInput): Observable<Microsatellite> {
    return this.http.post<Microsatellite>(environment.apiUrl + `/api/microsatellites`, data, {
      headers: getHeadersOption()
    });
  }
}
