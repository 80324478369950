<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectAnimal ? 'Редактирование' : 'Добавление' }} животного
  </h3>
  <div class="modal-body">
    <form class="horizontal" clrForm [formGroup]="form">

      <text-field [formGroup]="form" type="text" key="name" label="Кличка"></text-field>

      <text-field [formGroup]="form" type="text" key="inventoryNumber" label="Инвентарный номер" [required]="true"></text-field>


      <autocomplete [formGroup]="form" key="mother"
                    label="Мать" [options]="female"
                    [onInput]="onSearchMother"
                    [loading]="loadingAnimals"
                    [class]="'custom-combobox-width-95'"
      ></autocomplete>

      <autocomplete [formGroup]="form" key="father"
                    label="Отец" [options]="male"
                    [onInput]="onSearchFather"
                    [loading]="loadingAnimals"
                    [class]="'custom-combobox-width-95'"
      ></autocomplete>

      <autocomplete [formGroup]="form" key="kind"
                    label="Вид" [options]="kinds"
                    [onInput]="onSearchKinds"
                    [loading]="loadingKinds" [required]="true"
                    [class]="'custom-combobox-width-95'"
      ></autocomplete>

      <autocomplete [formGroup]="form" key="breed"
                    label="Порода" [options]="breeds"
                    [onInput]="onSearchBreeds"
                    [loading]="loadingBreeds" [required]="true" [class]="'custom-combobox-width-95'"
      ></autocomplete>


      <custom-select [formGroup]="form" key="gender" label="Пол" [options]="GENDERS" [class]="'custom-combobox-width-95'" [required]="true"></custom-select>

      <date-picker label="Дата рождения" key="birthDate" [formGroup]="form" [required]="true"></date-picker>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">Сохранить</button>
  </div>
</clr-modal>
