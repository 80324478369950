import { ClarityModule } from "@clr/angular";
import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

import { Order } from "@shared/models/order";

import { BitrixListComponent } from "../bitrix-list/bitrix-list.component";
import { BitrixService } from "@modules/bitrix/services/bitrix.service";

@Component({
  selector: 'bitrix',
  templateUrl: './bitrix.component.html',
  styleUrls: ['./bitrix.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClarityModule,
    BitrixListComponent
  ]
})

export class BitrixComponent {
  @Input() selectOrder?: Order | null;

  constructor( private bitrixService: BitrixService ) {}

  isOpen: boolean = false;
  
  exportToBitrix = (): void => {
    if (this.selectOrder) {
      this.bitrixService.exportToBitrix(this.selectOrder.id, null).subscribe({
        next: (): void => {
        },
        error: (): void => {
        }
      });
    }
  }

  handleCloseBitrixList(isOpen: boolean): void {
    this.isOpen = isOpen;
  }

}