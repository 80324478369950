import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";
import { CounterpartiesResponse } from "@shared/models/counterparty";
import { CounterpartiesFilters } from "@shared/models/counterparty";
import { CounterpartyInput } from "@shared/models/counterparty";
import { CounterpartyType } from "@shared/models/counterparty";
import { Counterparty } from "@shared/models/counterparty";

@Injectable({
  providedIn: 'root'
})
export class CounterpartyService {

  constructor(private http: HttpClient) {
  }

  get(filters: CounterpartiesFilters): Observable<CounterpartiesResponse> {
    return this.http.get<CounterpartiesResponse>(environment.apiUrl + `/api/contragents`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }
  
  getById(id: number): Observable<Counterparty> {
    return this.http.get<Counterparty>(environment.apiUrl + `/api/contragents/${id}`, {
      headers: getHeadersOption()
    });
  }

  getTypes(): Observable<CounterpartyType[]> {
    return this.http.get<CounterpartyType[]>(environment.apiUrl + `/api/contragents/types`, {
      headers: getHeadersOption()
    });
  }

  create(data: CounterpartyInput): Observable<Counterparty> {
    return this.http.post<Counterparty>(environment.apiUrl + `/api/contragents`, data, {
      headers: getHeadersOption()
    });
  }

  update(data: CounterpartyInput): Observable<Counterparty> {
    return this.http.patch<Counterparty>(environment.apiUrl + `/api/contragents`, data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/contragents/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<Counterparty> {
    return this.http.patch<Counterparty>(environment.apiUrl + `/api/contragents/${id}/restore`, {},{
      headers: getHeadersOption()
    });
  }
}
