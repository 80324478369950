import { Paginate } from "./paginate"

export enum Roles {
  MANAGER = 0,
  LABORATORYASSISTANT = 1,
  DOCTOR = 2,
  ADMINISTRATOR = 3,
}
export interface User {
  id: string
  userName: string
  firstName: string
  lastName: string
  middleName: string
  token: string
  roles: UserRole[]
  email: string
  isBlocked: boolean
  createdAt: string
  fullName?: string
}

export interface UserInput {
  userName: string
  firstName: string
  lastName: string
  middleName?: string
  email: string
  password: string
  roleIds: number[]
}

export interface UserChangePasswordInput {
  currentPassword: string
  newPassword: string
}

export interface UserRole {
  id: number
  name: string
  description: string | null
  normalizedName: string
  concurrencyStamp: string | null
}

export interface UsersFilters {
  userName?: string
  search?: string
  orderBy?: string
  isDeleted?: boolean
  offset?: number
  limit?: number
  createdAt?: string
}

export interface UsersResponse extends Paginate {
  data: User[]
}

export const getUserRoles = (roles: UserRole[]): string => roles.map((role: UserRole) => role.name).join(',');

export const ADMIN_ROLE = 'ADMINISTRATOR';
export const DOCTOR_ROLE = 'DOCTOR';
export const MANAGER_ROLE = 'MANAGER';
export const LABORATORYASSISTANT_ROLE = 'LABORATORYASSISTANT';
