import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractsResponse } from "@shared/models/contract";
import { ContractFilters } from "@shared/models/contract";
import { ContractInput } from "@shared/models/contract";
import { Contract } from '@shared/models/contract';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";
import { Status } from "@shared/models/status";

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient) {
  }

  get(filters: ContractFilters): Observable<ContractsResponse> {
    return this.http.get<ContractsResponse>(environment.apiUrl + `/api/contracts`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getById(id: number): Observable<Contract> {
    return this.http.get<Contract>(environment.apiUrl + `/api/contracts/${id}`, {
      headers: getHeadersOption()
    });
  }


  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(environment.apiUrl + '/api/contracts/statuses?limit=100', {
      headers: getHeadersOption()
    });
  }


  create(data: ContractInput): Observable<Contract> {
    return this.http.post<Contract>(environment.apiUrl + '/api/contracts', data, {
      headers: getHeadersOption()
    });
  }

  update(data: ContractInput): Observable<Contract> {
    return this.http.patch<Contract>(environment.apiUrl + '/api/contracts', data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/contracts/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/contracts/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }
}
