import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { User } from '@shared/models/user';
import { environment } from 'src/environments/environment';
import { getHeadersOption } from "@shared/helpers/headers";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router, private storageService: StorageService) {
  }

  login(username: string, password: string): Observable<User> {
    return this.http.post<User>(
      environment.apiUrl + '/api/auth/login',
      {
        username,
        password,
      },
      {
        headers: getHeadersOption(false)
      }
    );
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/api/auth/register',
      {
        username,
        email,
        password,
      },
      {
        headers: getHeadersOption(false)
      }
    );
  }


  logout() {
    this.storageService.clean();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
