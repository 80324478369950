<clr-main-container *ngIf="isLoggedIn">
  <div class="alert alert-app-level"></div>
  <app-header (logoutEvent)="logout()"></app-header>
  <div class="content-container">
    <router-outlet name="verticalSubmenu"></router-outlet>
    <div class="content-area">
      <app-error></app-error>
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
<app-login *ngIf="!isLoggedIn"></app-login>
