<div class="clr-row" cds-layout="p-t:md">
  <div class="clr-col-5" cds-layout="p:none">
    <button type="button"
            class="btn btn-outline"
            style="width: 100%;"
            (click)="isOpen = true"
    >ПОЛУЧИТЬ ИЗ БИТРИКС</button>
  </div>

  <div class="clr-col-7" cds-layout="p-r:none">
    <button type="button"
            class="btn btn-outline"
            style="width: 100%;"
            (click)="exportToBitrix()"
            [disabled]="!selectOrder"
    >ЭКСПОРТ ДАННЫХ В БИТРИКС</button>
  </div>
</div>

<bitrix-list *ngIf="isOpen"
             [isOpen]="isOpen"
             (isOpenChange)="handleCloseBitrixList($event)"
             [handleCloseBitrixList]="handleCloseBitrixList"
></bitrix-list>