import { ClarityModule } from "@clr/angular";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Order } from "@shared/models/order";
import { Entity } from "@shared/models/entity";
import { Document } from "@shared/models/document";
import { Contract } from "@shared/models/contract";
import { Research } from "@shared/models/research";
import { EntityService } from "@core/services/entity.service";
import { DocumentService } from "@core/services/document.service";
import { OrderService } from "@modules/orders/services/order.service";

@Component({
  selector: 'upload-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClarityModule,
  ]
})

export class UploadDocumentComponent implements OnInit {
  @Input() loading: boolean;
  @Input() ENTITY_NAME: string;
  @Input() selectOrder?: Order | null;
  @Input() selectEntity: Order | Contract | Research | null | number;

  @Output() filesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() formDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() entityChange: EventEmitter<Entity | undefined> = new EventEmitter<Entity | undefined>();


  @ViewChild('fileInput') set input(element: ElementRef) {
    if (element) {
      this.fileInput = element.nativeElement;
    }
  };

  constructor( private fb: FormBuilder,
               private orderService: OrderService,
               private entityService: EntityService,
               private documentService: DocumentService,
  ) {}

  form: FormGroup;
  files: Document[];
  entities: Entity[];
  entity: Entity | undefined;
  fileInput: HTMLInputElement;
  uploadDocument: HTMLInputElement;
  formData: FormData = new FormData();
  filesForm: any[];

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      files: new FormControl(this.files ?? []),
    });

  this.getEntities();
  }

  getEntities = (): void => {
      this.entityService.entities().subscribe({
        next: (data: Entity[]): void => {
          this.entities = Object.values(data);
          this.setEntity();
          this.selectEntity && this.getFiles(this.selectEntity);
        },
        error: (): void => {
        }
      });
  }

  setEntity(): Entity | undefined {
    this.entity = this.entities.find((entity: Entity): boolean => entity.entity === this.ENTITY_NAME);
    this.entityChange.emit(this.entity);
    return this.entity;
  }

  create1CBill = (): void => {
    if (this.selectOrder) {
      this.orderService.create1CBill(this.selectOrder.id, null).subscribe({
        next: (): void => {
        },
        error: (): void => {
        }
      });
    }
  }

  getFiles = (selectEntity: Order | Contract | Research | null | number): void => {
    if (this.entity && selectEntity) {
      const entityId = typeof selectEntity === 'number' ? selectEntity : selectEntity.id   
      this.documentService.getFiles(this.entity.id, entityId).subscribe({
        next: (response: Document[]): void => {
          this.files = Object.values(response);
          this.filesChange.emit(this.files); 
        },
        error: (): void => {
        }
      });
    }
  }

  downloadFile(event: Event, file: Document) {
    event.preventDefault();
     this.documentService.get(file.id, file.name);
  };
 
  resetFileInput(): void {
    this.fileInput.type = '';
    this.fileInput.type = 'file';
    this.form.value.files = null;
  }

  restoreFile = (id: number | null, index: number): void => this.updateFiles(id, index, false);
 

  deleteFile(id: number | null, index: number): void {

    if (id) {
      this.updateFiles(id, index, true);
    }
    const currentFiles: File[] = this.form.get('files')?.value || [];
    
    const newFormData = new FormData();
  
    currentFiles.forEach((file, i) => {
      if (i !== index) {
        newFormData.append('files', file, file.name);
      }
    });
  
    this.formData = newFormData;
  
    this.form.patchValue({
      files: currentFiles.filter((_, i) => i !== index)
    });
  
    this.formDataChange.emit(this.formData);
  }

  updateFiles = (id: number | null, index: number, isDeleted: boolean): void => {
    if (id) {
      this.files = this.files.map((item: Document, fileIndex: number) => {
        if (fileIndex != index) {
          return item;
        }
        const Action = isDeleted
          ? this.documentService.delete(item.id)
          : this.documentService.restore(item.id);

        Action.subscribe({
          next: (doc: Document): Document => {
            return doc;
          },
          error: (): void => {
          }
        });
        item.isDeleted = isDeleted;
        return item;
      }).filter((item: any) => item);
    } else {
      this.resetFileInput();
      const filesKey = 'files';
      const files = this.formData.getAll(filesKey);
      if (files && files.length > index) {
        this.formData.delete(filesKey);
      
        for (let i = 0; i < files.length; i++) {
          if (i !== index) {
            this.formData.append(filesKey, files[i]);
          }
        }
      }

      this.form.value.files = this.form.value.files?.filter((item: Document | File, fileIndex: number) => {
        (fileIndex != index || isDeleted) ? item : null;
      })
      this.formDataChange.emit(this.formData);
    }
  }

  addFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    const newFiles: FileList | null = target.files;
  
    if (newFiles && newFiles.length > 0) {
      const currentFiles: File[] = this.form.get('files')?.value || [];
      const filesToAdd: File[] = Array.from(newFiles);
      const updatedFiles = [...currentFiles, ...filesToAdd];

      this.form.patchValue({ files: updatedFiles });

      filesToAdd.forEach(file => {
        this.formData.append('files', file, file.name);
      });

      this.formDataChange.emit(this.formData);

      if (target) {
        target.value = '';
      }
    }
  }

}