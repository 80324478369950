import { Injectable } from '@angular/core';

import {TableSettings} from "@shared/models/table";
import { ADMIN_ROLE, UserRole, User } from "@shared/models/user";

export const USER_KEY = 'user';
export const TOKEN_KEY = 'user-token';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {
  }

  clean(): void {
    window.localStorage.clear();
  }

  public saveTableSettings(tableSettings: TableSettings) {
    localStorage.setItem(tableSettings.tableId, JSON.stringify(tableSettings));
  }

  public getTableSettings(tableId: string): TableSettings | null {
    const settings = localStorage.getItem(tableId);
    return settings ? JSON.parse(settings) : null;
  }

  public saveUser(user: User): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    window.localStorage.setItem(TOKEN_KEY, user.token);
  }

  public getUser(): User {
    const user: string | null = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return <User>{};
  }

  public isLoggedIn(): boolean {
    const user: User = this.getUser();
    return !!(user && user.id);
  }

  public isAdmin = (): boolean => {
    const user: User = this.getUser();
    return user.roles.findIndex((item: UserRole): boolean => item.normalizedName === ADMIN_ROLE) !== -1
  }

  public getRoles = (): UserRole[] => this.getUser().roles;
}
