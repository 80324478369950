import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const convertFormat = (dateString: string | null | undefined): string => {
  if (dateString) {
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}.${month}.${year}`;
  }
  return '';
}

export const convertDateForFilter = (inputDate: string): string => {
    const parts = inputDate.split('.');
    if (parts.length === 3) {
      const day = parts[0].padStart(2, '0');
      const month = parts[1].padStart(2, '0');
      const year = parts[2];
      return `${year}-${month}-${day}`;
    } else {
      return inputDate;
    }  
}


export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;

    if (!dateRegex.test(control.value) && control.value) {
      return { invalidFormat: true };
    }

    const parts = control.value.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      return { invalidDate: true };
    }

    return null;
  };
}