import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { getHeadersOption, getHttpParams } from "@shared/helpers/headers";
import { Doctor, DoctorInput, DoctorsFilters, DoctorsResponse } from '@shared/models/doctor';


@Injectable({
  providedIn: 'root'
})
export class DoctorsService {

  constructor(private http: HttpClient) {
  }

  get(filters: DoctorsFilters): Observable<DoctorsResponse> {
    return this.http.get<DoctorsResponse>(environment.apiUrl + `/api/doctors`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  create(data: DoctorInput): Observable<Doctor> {
    return this.http.post<Doctor>(environment.apiUrl + `/api/doctors`, data, {
      headers: getHeadersOption()
    });
  }

  update(data: DoctorInput): Observable<Doctor> {
    return this.http.patch<Doctor>(environment.apiUrl + `/api/doctors/`, data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/doctors/${id}/delete/soft`, {}, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/doctors/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }
}
