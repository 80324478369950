import { Component, EventEmitter, Output } from '@angular/core';
import { ClarityIcons, helixIcon } from '@cds/core/icon';
import '@cds/core/icon/register.js';

import { MenuItem } from '@shared/models/menuItem';
import { User, UserRole } from '@shared/models/user';
import { headerMenuItems } from '@constants/menu-items';
import { StorageService } from '@core/services/storage.service';

ClarityIcons.addIcons(helixIcon);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() logoutEvent = new EventEmitter();

  readonly user: User = <User>{};
  
  type: string = '';
  headerMenuItems: MenuItem[] = headerMenuItems;
  filteredMenuItems: MenuItem[] = [];

  constructor(
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.filterMenuItems();
  }

  getUser(): User {
    return this.storageService.getUser();
  }

  getRoles(): UserRole[] {
    return this.storageService.getRoles();
  }

  logout() {
    this.logoutEvent.emit();
  }

  private filterMenuItems(): void {
    const userRoles: UserRole[] = this.storageService.getRoles();
   
    this.filteredMenuItems = this.headerMenuItems.filter((item) => {
      if (item?.roles?.length) {
        const menuRoles: string[] = item.roles;
        return userRoles.some((role: UserRole) => menuRoles.includes(role.normalizedName));
      }

      return true;
    });
  }
}
