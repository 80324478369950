<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ research ? 'Редактирование' : 'Добавление' }} исследования
  </h3>
  <div class="modal-body" *ngIf="isFormInitialized">
    <form class="horizontal" clrForm [formGroup]="form">

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <text-field [formGroup]="form" type="text" key="result" label="Результат" [class]="'custom-input-width'"></text-field>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <custom-select [formGroup]="form" key="status" label="Статус" [options]="statuses" [required]="true" [class]="'custom-combobox-width'"></custom-select>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <autocomplete [formGroup]="form"
                        key="service"
                        label="Услуга"
                        renderKey="fullName"
                        [options]="services"
                        [onInput]="onSearchService"
                        [loading]="loading"
                        [required]="true"
                        [renderOption]="renderServiceName"
          ></autocomplete>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <autocomplete [formGroup]="form" key="order" label="Заказ" [options]="orders"
                        [onInput]="onSearchOrder"
                        [loading]="loading"
                        [renderOption]="renderOrderName"
                        renderKey="fullName"
                        [required]="true"
          ></autocomplete>
        </div>
      </div>

      <div class="actionItemsBlock">

        <div style="width: 83%">
            <autocomplete [formGroup]="form"
                          key="sample"
                          label="Образец:*"
                          renderKey="number"
                          [loading]="loading"
                          [options]="samples"
                          [onInput]="onSearchSample"
                          [renderOption]="renderSampleName"
            ></autocomplete>
        </div>

        <div class="actionBtns">
            <button type="button"
                    aria-label="add"
                    class="btn btn-link addBtn"
                    cds-layout="p-t:xxs m:none m-r:xxs"
                    (click)="addOrUpdateSampleHandler(true)"
                    [disabled]="!form?.value?.order?.id"
                    ><clr-icon shape="plus"></clr-icon>
            </button>
            <button type="button"
                    aria-label="add"
                    [disabled]="!sample"
                    class="btn btn-link addBtn"
                    cds-layout="p-t:xxs m:none"
                    (click)="addOrUpdateSampleHandler(false)"
                    ><clr-icon shape="pencil"></clr-icon>
            </button>
        </div>
    </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <clr-combobox-container class="clr-row clr-align-items-center">
            <label class="clr-col-md-3">Матери</label>
            <clr-combobox class="clr-col-md-9 custom-combobox-width"
                          (clrInputChange)="onSearchMother($event)"
                          [clrLoading]="loading"
                          formControlName="mothers"
                          clrMulti="true"
            >
              <ng-container *clrOptionSelected="let selected">
                {{renderParentName(selected)}}
              </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let option of mothers; field: 'name'"
                              [clrValue]="option">
                              {{renderParentName(option)}}
                  </clr-option>
                </clr-options>
            </clr-combobox>
          </clr-combobox-container>
        </div>
        <div class="clr-col-md-1 clr-align-self-center" cds-layout="p-t:md p-l:none">
            <button type="button"
                    aria-label="add"
                    class="btn btn-link addBtn"
                    cds-layout="p-t:xxs m:none m-r:xxs"
                    (click)="addAnimalHandler('mothers')"
                    ><clr-icon shape="plus"></clr-icon>
            </button>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
            <clr-combobox-container class="clr-row clr-align-items-center">
                <label class="clr-col-md-3">Отцы</label>
                <clr-combobox class="clr-col-md-9 custom-combobox-width"
                            (clrInputChange)="onSearchFather($event)"
                            [clrLoading]="loading"
                            formControlName="fathers"
                            clrMulti="true"
                >
                    <ng-container *clrOptionSelected="let selected">
                      {{renderParentName(selected)}}
                    </ng-container>
                    <clr-options>
                        <clr-option *clrOptionItems="let option of fathers; field: 'name'"
                                    [clrValue]="option">
                            {{renderParentName(option)}}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
            </clr-combobox-container>
        </div>
        <div class="clr-col-md-1 clr-align-self-center" cds-layout="p-t:md p-l:none">
            <button type="button"
                    aria-label="add"
                    class="btn btn-link addBtn"
                    cds-layout="p-t:xxs m:none m-r:xxs"
                    (click)="addAnimalHandler('fathers')"
                    ><clr-icon shape="plus"></clr-icon>
            </button>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <autocomplete [formGroup]="form"
                        key="subcontractor"
                        [loading]="loading"
                        label="Соисполнитель"
                        [options]="subcontractors"
                        [onInput]="onSearchPerformer"
          ></autocomplete>
        </div>
      </div>

        <div  *ngIf="form.get('subcontractor')?.value">
            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <clr-date-container class="clr-row">
                        <label class="clr-col-md-3">Дата отправки</label>
                        <input class="clr-col-md-9" type="text" clrDate formControlName="dateOfShipment">
                    </clr-date-container>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <text-field [formGroup]="form" type="text" key="invoiceNumber" label="Номер счета" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <clr-date-container class="clr-row">
                        <label class="clr-col-md-3">Дата счета</label>
                        <input class="clr-col-md-9" type="text" clrDate formControlName="dateOfInvoice">
                    </clr-date-container>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <text-field [formGroup]="form" type="number" key="invoiceAmount" label="Сумма счета" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <custom-select [formGroup]="form" key="currency" label="Валюта" [options]="currencies" [class]="'custom-combobox-width'"></custom-select>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <text-field [formGroup]="form" type="number" key="sumPerSample" label="Сумма на образец" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

        </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <autocomplete [formGroup]="form"
                        key="interDoctor"
                        [loading]="loading"
                        renderKey="fullName"
                        [options]="doctors"
                        [onInput]="onSearchDoctor"
                        label="Интерпретирующий врач"
                        [renderOption]="renderDoctorName"
          ></autocomplete>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <clr-date-container class="clr-row">
            <label class="clr-col-md-3">Дата поступления образцов</label>
            <input class="clr-col-md-9" type="text" clrDate formControlName="admissionOfficeDate" [disabled]="true">
          </clr-date-container>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <date-picker [formGroup]="form"
                      key="deadline"
                      label="Дедлайн"
          ></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <date-picker [formGroup]="form"
                      key="dateOfInter"
                      label="Дата назначения интерпретации"
          ></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <date-picker [formGroup]="form"
                      key="dateOfGettingResult"
                      label="Дата получения результата"
          ></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <date-picker [formGroup]="form"
                      key="dateOfStartIssue"
                      label="Дата запуска в работу в лаборатории"
          ></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10 custom-combobox">
          <date-picker [formGroup]="form"
                      key="dateOfIssue"
                      label="Дата выдачи"
          ></date-picker>
        </div>
      </div>

      <div class="divider"></div>

      <upload-document [loading]="loading"
                       [ENTITY_NAME]="'Research'"
                       [selectEntity]="selectResearch"
                       (filesChange)="onFilesChange($event)"
                       (entityChange)="onEntityChange($event)"
                       (formDataChange)="onFormDataChange($event)"
      ></upload-document>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button *ngIf="isFormInitialized" type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">Сохранить</button>
  </div>
</clr-modal>

<ng-container *ngIf="isOpenSampleModal">
  <app-sample [samples]="samples"
              [selectSample]="sample"
              [orderType]="orderType"
              [handleClose]="handleCloseSample"
              (closeButtonClicked)="handleCloseSample()"
              (createSampleFromResearch)="handleCreateSampleEvent($event)"
  ></app-sample>
</ng-container>

<ng-container *ngIf="isOpenAnimalModal">
  <app-animal [handleClose]="handleCloseAnimal"
              [addOrUpdateAnimal]="addOrUpdateAnimal"
              [currentAnimalInput]="currentAnimalInput"
              (closeButtonClicked)="handleCloseAnimal()"
              (createAnimalWithInput)="handleCreateAnimalEvent($event)"
  ></app-animal>

</ng-container>
