import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { AuthService } from "@core/services/auth.service";
import { ErrorService } from '@core/services/error.service';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private errorMessage = 'Ошибка запроса.';

  constructor(
    private authService: AuthService,
    private errorService: ErrorService
  ) {}


  private handleError(response: HttpResponse<any>) {
    if ((response.status === 200  || response.status === 201) && response.body?.errors) {
      const errorString = response.body.errors.join('<br/>');
      this.errorService.handle({ type: 'error', content: errorString });
      return of(null);
    }
    return of(null);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.body?.errors) {
              this.handleError(event);
            } 
            if (event.body?.message) {
              this.handleMessage(event);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        },
      })
    );
  }

  private handleMessage(response: HttpResponse<any>) {
    if ((response.status === 200 || response.status === 201) && response.body?.message) {
      const message = response?.body?.message ?? '';
      this.errorService.handle({ type: 'message', content: message });
      return of(null);
    }
    return of(null);
  }

  private handleErrorResponse(error: HttpErrorResponse) {
    const customErrorMessage = error?.error?.error?.message || error?.error?.message || '';

    const messages = error?.error?.value?.messages?.join('<br/>') || '';

    const fullErrorMessage = `${customErrorMessage}<br/>` + messages;

    switch (error.status) {
      case 400:
      case 404:
      case 409:
      case 422:
      case 500:
      case 503:
        this.errorService.clear();
        this.errorService.handle({
          type: 'error',
          content: fullErrorMessage,
        });
        return of(null);
      case 401:
        if (error.error === null) {
          this.authService.logout();
        }
        return of(null);
      case 403:
        if (error.error === null) {
          this.authService.logout();
        }
        this.errorService.handle({ type: 'error', content: 'Ошибка авторизации' });
        return of(null);
      default:
        this.errorService.handle({ type: 'error', content: error.message });
        return of(null);
    }
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];