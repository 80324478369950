import { Component } from '@angular/core';
import { ErrorService, Message } from '@core/services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  errorArray: any = [];
  clrAlertType: string = 'danger';

  constructor(public errorService: ErrorService) {
  }

  handleClose = () => {
    this.errorService.clear();
  }

  ngOnInit(): void {
    this.errorService.message$.subscribe((message: Message) => {
      switch (message.type) {
        case 'error':
          this.errorArray = message.content ? message.content.split('<br/>') : [];
          this.clrAlertType = 'danger';
          break;
        case 'message':
          this.errorArray = message.content ? message.content.split(', ') : [];
          this.clrAlertType = 'success';
          break;
        case 'clear':
          this.errorArray = [];
          this.clrAlertType = 'danger';
          break;
        default:
          break;
      }
    });
  }
}
