<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false" [clrModalSize]="'lg'">
    <h3 class="modal-title">
        <btn (click)="handleCloseButton()"></btn>
        {{ selectOrder ? 'Редактирование' : 'Добавление' }} заказа
    </h3>
    <div class="modal-body">
        <form class="horizontal" clrForm [formGroup]="form">
            <div style="width: 81.5%; display: flex; justify-content: flex-end;">
                <bitrix style="width: 72%;"
                        [selectOrder]="selectOrder"
                ></bitrix>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10 custom-combobox">
                    <custom-select key="type"
                        label="Тип заказа (направление)"
                        [formGroup]="form"
                        [options]="types"
                        [required]="true"
                        [class]="'custom-combobox-width'"
                    ></custom-select>
                </div>
            </div>

            <div class="actionItemsBlock">

                <div style="width: 83%">
                    <autocomplete [formGroup]="form"
                                  key="contract"
                                  label="№ договора"
                                  renderKey="number"
                                  [loading]="loading"
                                  [options]="contracts"
                                  [onInput]="onSearchContract"
                                  [renderOption]="renderContractName"
                    ></autocomplete>
                </div>

                <div class="actionBtns">
                    <button type="button"
                            aria-label="add"
                            class="btn btn-link addBtn"
                            cds-layout="p-t:xxs m:none m-r:xxs"
                            (click)="addOrUpdateContractHandler(true)"
                            ><clr-icon shape="plus"></clr-icon>
                    </button>
                    <button type="button"
                            aria-label="add"
                            [disabled]="!contract"
                            class="btn btn-link addBtn"
                            cds-layout="p-t:xxs m:none"
                            (click)="addOrUpdateContractHandler(false)"
                    ><clr-icon shape="pencil"></clr-icon></button>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <text-field type="text"
                                key="number"
                                label="№ заказа"
                                [formGroup]="form"
                                [class]="'custom-input-width'"
                    ></text-field>
                </div>
            </div>

            <div *ngIf="form.value.type && form.value.type?.id === OrderTypes.ANIMAL"
                 [ngClass]="{'shown': form.value.type.id === OrderTypes.ANIMAL && !selectOrder}"
            >
            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <text-field type="text"
                                key="addictionNumber"
                                label="№ приложения"
                                [formGroup]="form"
                                [class]="'custom-input-width'"
                    ></text-field>
                </div>
            </div>
            </div>


            <div style="display: flex; justify-content: space-between; width: 83%;" cds-layout="p-t:lg">
                <div  style="display: flex; align-items: center; gap: 1.5rem">
                    <div cds-text="section">Исследования:</div>
                    <div *ngIf="selectOrder">количество образцов {{selectOrder?.samplesCount}}, количество исследований {{selectOrder?.researchesCount}}</div>
                </div>
            </div>

            <div style="width: 81.5%; display: flex; justify-content: flex-end;">
                <div style="width: 72%;">
                <div class="clr-row" cds-layout="p-t:md">
                    <div class="clr-col-6" cds-layout="p:none">
                      <button type="button"
                              class="btn btn-outline"
                              style="width: 100%;"
                              (click)="goTo()"
                              [disabled]="!selectOrder?.researchesCount"
                      >Перейти к исследованиям</button>
                    </div>
                  
                    <div class="clr-col-6" cds-layout="p-r:none">
                      <button type="button"
                              class="btn btn-outline"
                              style="width: 100%;"
                              (click)="addOrCreateResearches()"
                      >+ Добавить новое
                      <clr-icon *ngIf="!selectOrder" shape="asterisk" class="is-solid is-error" size="12"></clr-icon>
                    </button>
                    </div>
                  </div>
                </div>
            </div>

            <div formArrayName="researches" *ngIf="selectOrder?.type?.id !== OrderTypes.ANIMAL">
                <ng-container *ngFor="let research of researches.controls; let i = index" [formGroupName]="i">
                    <ng-container *ngIf="!research.value.isDeleted">

                        <div class="divider"></div>

                        <div class="clr-row clr-justify-content-end" cds-layout="p-r:md">
                            <button type="button" class="delete" aria-label="delete research" (click)="deleteResearch(i)">
                                <clr-tooltip>
                                    <clr-icon clrTooltipTrigger shape="trash" size="24"></clr-icon>
                                    <clr-tooltip-content clrPosition="top-left" clrSize="lg" *clrIfOpen>
                                    <span>Удалить исследование</span>
                                    </clr-tooltip-content>
                                </clr-tooltip>
                            </button>
                        </div>

                        <div class="clr-row clr-align-items-flex-start" cds-layout="p-t:md">
                            <div class="clr-col-md-10" cds-layout="m-t:none">
                                <clr-combobox-container class="clr-row clr-align-items-center"  cds-layout="m-t:xxs">
                                    <label class="clr-col-md-3">Образец:*</label>
                                    <clr-combobox class="clr-col-md-9 custom-combobox-width"
                                    formControlName="sample"
                                    (clrInputChange)="onSearchSample($event)"
                                    [clrLoading]="loading"
                                    >
                                        <clr-options>
                                            <clr-option [clrValue]="option" *clrOptionItems="let option of samples; field: 'fullName'">
                                                {{renderSampleName(option)}}
                                            </clr-option>
                                        </clr-options>
                                    </clr-combobox>
                                    <clr-control-error>Выберите Образец из списка или добавьте новый</clr-control-error>
                                </clr-combobox-container>
                            </div>

                            <div class="actionBtns">
                                <button type="button"
                                        aria-label="add"
                                        class="btn btn-link addBtn"
                                        cds-layout="p-t:xxs m:none m-r:xxs"
                                        (click)="addOrUpdateSampleHandler(i, true)"
                                        ><clr-icon shape="plus"></clr-icon>
                                </button>
                                <button type="button"
                                        aria-label="edit"
                                        class="btn btn-link addBtn"
                                        cds-layout="p-t:xxs m:none"
                                        (click)="addOrUpdateSampleHandler(i, false)"
                                        ><clr-icon shape="pencil"></clr-icon>
                                </button>
                                <button type="button"
                                        aria-label="copy"
                                        class="btn btn-link addBtn"
                                        cds-layout="p-t:xxs m:none"
                                        (click)="addOrUpdateSampleHandler(i, false, true)"
                                        ><clr-icon shape="copy"></clr-icon>
                                </button>
                            </div>
                        </div>


                        <div class="clr-row clr-align-items-end" style="cursor: not-allowed;">
                            <div class="clr-col-md-10">
                                <clr-date-container class="clr-row">
                                    <label class="clr-col-md-3">Дата поступления образцов</label>
                                    <input class="clr-col-md-9" type="text" clrDate formControlName="admissionOfficeDate" [disabled]="true">
                                </clr-date-container>
                            </div>
                        </div>


                        <div *ngIf="form.value.type && form.value.type?.id === OrderTypes.ANIMAL"
                        [ngClass]="{'shown': form.value.type.id === OrderTypes.ANIMAL && !selectOrder}"
                   >
                            <div class="clr-row clr-align-items-end">
                                <div class="clr-col-md-10">
                                    <clr-combobox-container class="clr-row clr-align-items-center">
                                        <label class="clr-col-md-3">Матери</label>
                                        <clr-combobox class="clr-col-md-9 custom-combobox-width"
                                                    (clrInputChange)="onSearchMother($event)"
                                                    [clrLoading]="loading"
                                                    formControlName="mothers"
                                                    clrMulti="true"
                                        >
                                            <ng-container *clrOptionSelected="let selected">
                                                {{selected.name ? selected.name : selected.inventoryNumber }}
                                            </ng-container>
                                            <clr-options>
                                                <clr-option *clrOptionItems="let option of mothers; field: 'name'"
                                                            [clrValue]="option">
                                                    {{renderParentName(option)}}
                                                </clr-option>
                                            </clr-options>
                                        </clr-combobox>
                                    </clr-combobox-container>
                                </div>
                                <div class="clr-col-md-1 clr-align-self-center" cds-layout="p-t:md p-l:none">
                                    <button type="button"
                                            aria-label="add"
                                            class="btn btn-link addBtn"
                                            cds-layout="p-t:xxs"
                                            (click)="addAnimalHandler(i, 'mothers')"
                                    ><clr-icon shape="plus"></clr-icon></button>
                                </div>
                            </div>

                            <div class="clr-row clr-align-items-end">
                                <div class="clr-col-md-10">
                                    <clr-combobox-container class="clr-row clr-align-items-center">
                                        <label class="clr-col-md-3">Отцы</label>
                                        <clr-combobox class="clr-col-md-9 custom-combobox-width"
                                                    (clrInputChange)="onSearchFather($event)"
                                                    [clrLoading]="loading"
                                                    formControlName="fathers"
                                                    clrMulti="true"
                                        >
                                            <ng-container *clrOptionSelected="let selected">
                                                {{selected.name? selected.name : selected.inventoryNumber }}
                                            </ng-container>
                                            <clr-options>
                                                <clr-option *clrOptionItems="let option of fathers; field: 'name'"
                                                            [clrValue]="option">
                                                    {{renderParentName(option)}}
                                                </clr-option>
                                            </clr-options>
                                        </clr-combobox>
                                    </clr-combobox-container>
                                </div>
                                <div class="clr-col-md-1 clr-align-self-center" cds-layout="p-t:md p-l:none">
                                    <button type="button"
                                            aria-label="add"
                                            class="btn btn-link addBtn"
                                            cds-layout="p-t:xxs"
                                            (click)="addAnimalHandler(i, 'fathers')"
                                    ><clr-icon shape="plus"></clr-icon></button>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row">
                            <div class="clr-col-md-10">
                                <clr-combobox-container class="clr-row clr-align-items-center">
                                    <label class="clr-col-md-3">Услуги:*</label>
                                    <clr-combobox class="clr-col-md-9 custom-combobox-width" (clrInputChange)="onSearchService($event)"
                                                [clrLoading]="loading" formControlName="services" clrMulti="true">
                                        <ng-container *clrOptionSelected="let selected">
                                            {{selected.shortName}}
                                        </ng-container>
                                        <clr-options>
                                            <clr-option *clrOptionItems="let option of services; field: 'fullName'"
                                                        [clrValue]="option">
                                                {{renderServiceName(option)}}
                                            </clr-option>
                                        </clr-options>
                                    </clr-combobox>
                                    <clr-control-error>Выберите Услугу из списка</clr-control-error>
                                </clr-combobox-container>
                            </div>
                        </div>

                        <div class="clr-row">
                            <div class="clr-col-md-10">
                                <clr-combobox-container class="clr-row">
                                    <label class="clr-col-md-3">Статус:*</label>
                                    <clr-combobox class="clr-col-md-9 custom-combobox-width" formControlName="status">
                                        <clr-options>
                                            <clr-option *clrOptionItems="let option of researchStatuses; field: 'name'"
                                                        [clrValue]="option">
                                                {{option.name}}
                                            </clr-option>
                                        </clr-options>
                                    </clr-combobox>
                                    <clr-control-error>Выберите Статус из списка</clr-control-error>
                                </clr-combobox-container>
                            </div>
                        </div>

                    </ng-container>

                </ng-container>
            </div>
            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                  <text-field [formGroup]="form" type="number" key="amount" label="Сумма заказа" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
              <text-field [formGroup]="form" type="number" key="paidSum" label="Оплаченная сумма" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <date-picker label="Дата оплаты" key="dateOfPayment" [formGroup]="form"></date-picker>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <text-field [formGroup]="form" type="text" key="comment" label="Комментарий" [class]="'custom-input-width'"></text-field>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <autocomplete [renderOption]="getLocalityName"
                                  key="locality"
                                  [formGroup]="form"
                                  [loading]="loading"
                                  [options]="localities"
                                  label="Населенный пункт"
                                  [onInput]="onSearchLocality"
                                  [class]="'custom-combobox-width'"
                    ></autocomplete>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <autocomplete [formGroup]="form"
                                  key="subdivision"
                                  renderKey="name"
                                  [loading]="loading"
                                  label="Подразделение"
                                  [options]="typesSubdivision"
                                  [onInput]="onSearchSubdivision"
                                  [renderOption]="renderSubdivisionName"
                                  [class]="'custom-combobox-width'"
                    ></autocomplete>
                </div>
            </div>

            <div *ngIf="form.value.type && form.value.type?.id !== OrderTypes.ANIMAL"
                 [ngClass]="{'shown': form.value.type.id !== OrderTypes.ANIMAL && !selectOrder}"
            >

                <div class="actionItemsBlock">

                    <div style="width: 83%">
                        <autocomplete key="doctor"
                                    label="Врач (направляющий)"
                                    [formGroup]="form"
                                    [options]="doctors"
                                    [loading]="loading"
                                    renderKey="fullName"
                                    class="customInputWidth"
                                    [onInput]="onSearchDoctor"
                                    [renderOption]="renderDoctorName"
                                    [class]="'custom-combobox-width'"
                        ></autocomplete>
                    </div>

                    <div class="actionBtns">
                        <button type="button"
                                aria-label="add"
                                class="btn btn-link addBtn"
                                cds-layout="p-t:xxs m:none m-r:xxs"
                                (click)="addOrUpdateDoctorHandler(true)"
                                ><clr-icon shape="plus"></clr-icon>
                        </button>
                        <button type="button"
                                aria-label="add"
                                [disabled]="!doctor"
                                class="btn btn-link addBtn"
                                cds-layout="p-t:xxs m:none"
                                (click)="addOrUpdateDoctorHandler(false)"
                        ><clr-icon shape="pencil"></clr-icon></button>
                    </div>
                </div>

                <div class="clr-row clr-align-items-end">
                    <div class="clr-col-md-10">
                        <text-field [formGroup]="form" type="text" key="diagnosis" label="Направительный диагноз" [class]="'custom-input-width'"></text-field>
                    </div>
                </div>

            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <custom-select [formGroup]="form"
                                   key="status"
                                   label="Статус"
                                   [options]="orderStatuses"
                                   [class]="'custom-combobox-width'"
                    ></custom-select>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <date-picker label="Дата поступления образцов" key="dateOfSamplesArrived" [formGroup]="form"></date-picker>
                </div>
            </div>

            <div class="clr-row clr-align-items-end">
                <div class="clr-col-md-10">
                    <date-picker label="Дедлайн" key="deadline" [formGroup]="form"></date-picker>
                </div>
            </div>

            <div class="clr-row" cds-layout="p-t:md">
                <div class="clr-col-sm-12 clr-col-md-12">
                    <upload-document [loading]="loading"
                                     [ENTITY_NAME]="'Order'"
                                     [selectEntity]="selectOrder"
                                     [selectOrder]="selectOrder"
                                     (filesChange)="onFilesChange($event)"
                                     (entityChange)="onEntityChange($event)"
                                     (formDataChange)="onFormDataChange($event)"
                    ></upload-document>
                </div>
            </div>

        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
        <button type="button" [disabled]="form.status === 'INVALID' || (!selectOrder?.id && selectOrder?.researchesCount === 0) || !form.dirty" class="btn btn-primary" (click)="handleSaveButton()">
            Сохранить
        </button>
    </div>
</clr-modal>
<ng-container *ngIf="isOpenSampleModal">
    <app-sample [orderName]="orderName"
                [orderType]="orderType"
                [orderTypes]="types"
                [isCopy]="isCopy"
                [samples]="samples"
                [selectSample]="sample"
                [currentNumber]="currentNumber"
                [handleClose]="handleCloseSample"
                [addOrUpdateItem]="addOrUpdateItem"
                (closeButtonClicked)="handleCloseSample()"
                (createSample)="handleCreateSampleEvent($event)"
                ></app-sample>
</ng-container>
<ng-container *ngIf="isOpenAnimalModal">
    <app-animal [handleClose]="handleCloseAnimal"
                [addOrUpdateAnimal]="addOrUpdateAnimal"
                [currentAnimalInput]="currentAnimalInput"
                [currentAnimalIndex]="currentAnimalIndex"
                (closeButtonClicked)="handleCloseAnimal()"
                (createAnimalWithIndex)="handleCreateAnimalEvent($event)"
    ></app-animal>

  </ng-container>
<ng-container *ngIf="isOpenContractModal">
  <app-contract [handleClose]="handleCloseContract"
                [contracts]="contracts"
                [selectContract]="contract"
                [addOrUpdateItem]="addOrUpdateItem"
                (closeButtonClicked)="handleCloseContract()"
                (createContract)="handleCreateContractEvent($event)"
  ></app-contract>
</ng-container>
<ng-container *ngIf="isOpenResearchModal">
    <app-research [selectOrder]="selectOrder"
                [handleClose]="handleCloseResearch"
                (closeButtonClicked)="handleCloseResearch()"
    ></app-research>
</ng-container>
<ng-container *ngIf="isOpenDoctorModal">
  <app-doctor [handleClose]="handleCloseDoctor"
              [doctors]="doctors"
              [selectDoctor]="doctor"
              [addOrUpdateItem]="addOrUpdateItem"
              (closeButtonClicked)="handleCloseDoctor()"
              (createDoctor)="handleCreateDoctorEvent($event)"
  ></app-doctor>
</ng-container>
