import { Locality } from "./gar";
import { PlanStreet } from "./gar";
import { Paginate } from "./paginate";

export enum ContragentType {
  LEGAL = 1,
  INDIVIDUAL_ENTREPRENEUR = 2,
  INDIVIDUAL = 3,
}

export interface Counterparty {
  id: number
  name: string
  typeId: number
  type: CounterpartyType
  inn: number
  kpp: number
  isPerformer: boolean
  physicalAddressLocalityIdAddrObj: number
  physicalAddressPlanStreetIdAddrObj: number
  legalAddressLocalityIdAddrObj: number
  legalAddressPlanStreetIdAddrObj: number
  physicalAddressLocalityId: number
  physicalAddressLocality: Locality
  physicalAddressPlanStreetId: number
  physicalAddressPlanStreet: PlanStreet
  physicalAddressHouseNumber: string
  physicalAddressBuildingNumber: string
  physicalAddressOfficeNumber: string
  legalAddressLocalityId: number
  legalAddressLocality: Locality
  legalAddressPlanStreetId: number
  legalAddressPlanStreet: PlanStreet
  legalAddressHouseNumber: string
  legalAddressBuildingNumber: string
  legalAddressOfficeNumber: string
  phone: string
  email: string
  isDeleted: boolean
  createdAt: string
}

export interface CounterpartyType {
  id: number
  name: string
  type: number
}

export interface CounterpartyInput {
  id?: number | null
  name: string
  typeId: number
  inn: number
  kpp: number
  email: string
  phone: string
  isPerformer: boolean
  physicalAddressLocalityId: number;
  physicalAddressLocalityIdAddrObj: number;
  physicalAddressPlanStreetId: number
  physicalAddressPlanStreetIdAddrObj: number
  physicalAddressHouseNumber: string
  physicalAddressBuildingNumber: string
  physicalAddressOfficeNumber: string
  legalAddressLocalityId: number
  legalAddressLocalityIdAddrObj: number
  legalAddressPlanStreetId: number
  legalAddressPlanStreetIdAddrObj: number
  legalAddressHouseNumber: string
  legalAddressBuildingNumber: string
  legalAddressOfficeNumber: string
  isDeleted?: boolean
}

export interface CounterpartiesFilters {
  search?: string
  orderBy?: string
  isDeleted?: boolean
  isPerformer?: boolean
  offset?: number
  limit?: number
  type?: number
  createdAt?: string
}

export interface CounterpartiesResponse extends Paginate {
  data: Counterparty[]
}
