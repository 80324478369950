import { NgModule } from '@angular/core';
import { ClarityModule } from "@clr/angular";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthService } from './core/services/auth.service';

import { ErrorComponent } from './core/components/error/error.component';
import { HeaderComponent } from './core/components/header/header.component';
import { HistoryComponent } from './core/components/history/history.component';
import { SelectComponent } from "./core/components/input/select/select.component";
import { UploadDocumentComponent } from './core/components/documents/document.component';
import { TextFieldComponent } from "./core/components/input/text-field/text-field.component";
import { PageContentComponent } from "./core/components/page-content/page-content.component";
import { DatepickerComponent } from "./core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "./core/components/input/autocomplete/autocomplete.component";

import { httpInterceptorProviders } from './shared/helpers/http.interceptor';

import { OrderComponent } from './modules/orders/components/order/order.component';
import { SampleComponent } from "./modules/samples/components/sample/sample.component";
import { ContractComponent } from "./modules/contracts/components/contract/contract.component";

import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    ErrorComponent,
    HeaderComponent,
    NotFoundComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    ClarityModule,
    OrderComponent,
    SelectComponent,
    SampleComponent,
    AppRoutingModule,
    HttpClientModule,
    HistoryComponent,
    ContractComponent,
    TextFieldComponent,
    ReactiveFormsModule,
    DatepickerComponent,
    PageContentComponent,
    AutocompleteComponent,
    UploadDocumentComponent,
    BrowserAnimationsModule
  ],
  providers: [httpInterceptorProviders, AuthService],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {
}
