import { Paginate } from "./paginate";

export interface ConclusionServiceTypeServices {
  conclusionServiceType: ConclusionType,
  id: number
}
export interface ConclusionType {
  id: number
  name: string
  checked?: boolean
  conclusionServiceType: string
}
export interface Service {
  id: number
  name: string
  shortName: string
  conclusionServiceTypeServices?: ConclusionServiceTypeServices[]
  price: number
  deadline: number
  isDeleted: boolean
  createdAt: string
  fullName?: string
}

export interface ServiceInput {
  id?: number | null
  name: string
  shortName: string
  conclusionServiceTypeIds?: number[]
  price: number
  deadline: number
  isDeleted: boolean
}

export interface ServiceFilters {
  search?: string
  limit?: number
  orderBy?: string
  isDeleted?: boolean
  status?: number
  offset?: number
  createdAt?: string
}

export interface ServicesResponse extends Paginate {
  data: Service[]
}

export interface ConclusionTypesResponse extends Paginate {
  data: ConclusionType[]
}

export const renderServiceName = (service: Service) => `${service.shortName}: ${service.name}`;

export const renderService = (service: Service, isCut: boolean = false) => isCut ? (service.name.length > 50 ? service.name.slice(0, 40) + "..." : service.name) : service.shortName;

export const conclusionTypeName = (conclusionServiceType: ConclusionType) => conclusionServiceType.name;