import { ClrCommonStrings } from "@clr/angular";

export const ruLocale: Partial<ClrCommonStrings> = {
  showColumns: 'Скрыть столбцы',
  selectAll: 'Выбрать все',
  pickColumns: 'Настройки',
  comboboxNoResults: 'Нет результатов',
  comboboxSearching: 'Поиск...',
  filterItems: 'Поиск'
}
