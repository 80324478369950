<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectDoctor ? 'Редактирование' : 'Добавление' }} врача
  </h3>
  <div class="modal-body">
    <form class="horizontal" clrForm [formGroup]="form">
      <text-field [formGroup]="form" type="text" key="lastName" label="Фамилия" [required]="true"></text-field>
      <text-field [formGroup]="form" type="text" key="firstName" label="Имя"></text-field>
      <text-field [formGroup]="form" type="text" key="middleName" label="Отчество"></text-field>
      <autocomplete [renderOption]="getLocalityName"
                    [formGroup]="form"
                    [loading]="loading"
                    key="locality"
                    [options]="localities"
                    label="Населенный пункт"
                    [onInput]="onSearchLocality"
                    [class]="'custom-combobox-width-95'"
      ></autocomplete>
      <text-field [formGroup]="form" type="text" key="contractNumber" label="Номер договора"></text-field>

      <custom-select key="orderTypeId"
                    label="Тип заказа (направление)"
                    [formGroup]="form"
                    class="custom-combobox"
                    [options]="types"
                    [class]="'custom-combobox-width-95'"
      ></custom-select>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-11">
          <date-picker label="Дата договора" key="contractDate" [formGroup]="form"></date-picker>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button" [disabled]="form.status !== 'VALID'" class="btn btn-primary" (click)="handleSaveButton()">Сохранить</button>
  </div>
</clr-modal>
