import { Locality } from "./gar"
import { OrderType } from "./order"
import { Paginate } from "./paginate"
import { InterDoctor } from "./research"
export interface Doctor {
  id: number
  lastName: string
  firstName: string
  createdAt: string
  isDeleted: boolean
  middleName?: string
  contractDate: string
  contractNumber: string
  fullName?: string
  locality: Locality
  orderType: OrderType
}

export interface DoctorInput {
  firstName: string
  lastName: string
  isDeleted: boolean
  middleName?: string
  contractNumber: string
  localityIdAddrObj: number
  contractDate: string | null
  orderTypeId: number
}

export interface DoctorsFilters {
  limit?: number
  offset?: number
  search?: string
  orderBy?: string
  firstName?: string
  createdAt?: string
  isDeleted?: boolean
}

export interface DoctorsResponse extends Paginate {
  data: Doctor[]
}

export const renderDoctorName = (doctor: Doctor | InterDoctor) => {
  return `${doctor.lastName} ${doctor?.firstName ?? ''} ${doctor?.middleName ?? ''}`
};