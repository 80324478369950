import { Human } from "./human";
import { Animal } from "./animal";
import { Biomat } from "./biomat";
import { Status } from "./status";
import { Paginate } from "./paginate";

export interface Sample {
  id: number
  number: string
  statusId: number
  status: Status
  animalId?: number
  animal?: Animal
  biomatId: number
  biomat: Biomat
  potentialMothers?: PotentialParent[]
  potentialFathers?: PotentialParent[]
  drawDate: string
  createdAt?: string
  admissionLabDate?: string
  admissionOfficeDate?: string
  isStorage: boolean
  shelfLife: string
  isDeleted: boolean
  type: {
    id: number,
    name: string
  }
  sampleType: {
    id: number,
    name: string
  }
  sampleTypeSubdivision: {
    id: number,
    name: string
  }
  gender?: string
  defGender?: string
  defOligonucleotide?: string
  oligonucleotide?: string
  human?: Human
  humanId?: number
  fullName?: string
}

export interface PotentialParent {
  id: number
  name: string
  animalId: number
  birthDate: string
  inventoryNumber: string
}

export interface SampleInput {
  id?: number | null
  number: string
  statusId: number
  animalId?: number
  biomatId: number
  drawDate: string | null
  admissionOfficeDate: string | null
  admissionLabDate: string | null
  isStorage: boolean
  shelfLife?: string | null
  isDeleted?: boolean
  defGender?: string
  defOligonucleotideId?: number
  oligonucleotideId?: number
  humanId?: number
  gender?: string
}

export interface ChangeStatusInput {
  ids: number[],
  statusId: number
}

export interface SampleFilters {
  typeSubdivisionName?: string
  admissionOfficeDateBegin?: string
  admissionOfficeDateEnd?: string
  admissionLabDateBegin?: string
  admissionLabDateEnd?: string
  createdAtBegin?: string
  createdAtEnd?: string
  isDeleted?: boolean
  orderBy?: string
  search?: string
  status?: number
  offset?: number
  limit?: number
  typeId?: number
}

export interface SamplesResponse extends Paginate {
  data: Sample[]
}

export const renderSampleName = (sample: Sample) => {
  return `
    ${sample.number}
    ${sample?.animal?.name ?? ''}
    ${sample?.human?.fullName ?? ''}
  `
};

export const defaultSampleStatus: Status = {
  name: "Готов к работе (В лаборатории)",
  status: "ReadyInLab",
  id: 5
}
