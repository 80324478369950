import { Counterparty } from "./counterparty";
import { Document } from "./document";
import { Paginate } from "./paginate";
import { Status } from "./status";

export interface Contract {
  id: number
  number: string
  contragentId: number
  performerId: number
  contragent: Counterparty
  performer: Counterparty
  dateConclusion: string
  dateEnding: string
  statusId: number
  status: Status
  files: Document[]
  isDeleted: boolean
  createdAt: string
}


export interface ContractInput {
  id?: number | null
  number: string
  contragentId: number
  performerId: number
  dateConclusion: string
  dateEnding?: string | null
  statusId: number
  isDeleted?: boolean
  createdAt?: string
}

export interface ContractFilters {
  search?: string
  status?: number
  orderBy?: string
  isDeleted?: boolean
  dateConclusionBegin?: string
  dateConclusionEnd?: string
  dateEndingBegin?: string
  dateEndingEnd?: string
  createdAtBegin?: string
  createdAtEnd?: string
  offset?: number
  limit?: number
}

export interface ContractsResponse extends Paginate {
  data: Contract[]
}

export const renderContractName = (contract: Contract) => contract.number;

export const defaultContractStatus: Status = {
  name: "Активный",
  status: "Active",
  id: 2
}