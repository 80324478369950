import { convertFormat } from "./date";

export const translateToRusParam = (param: string): string => {
  const roleMappings: { [key: string]: string } = {
    "Files": 'Файлы',
    "Number": 'Номер',
    "Amount": 'Сумма',
    "StatusId": 'Статус',
    "AnimalId": 'Кличка',
    "Result": 'Результат',
    "IsDeleted": 'Удален',
    "Deadline": 'Дедлайн',
    "IsStorage": 'Хранение',
    "BiomatId": 'Биоматериал',
    "DrawDate": 'Дата забора',
    "ShelfLife": 'Срок хранения',
    "ContragentId": 'Контрагент',
    "CreatedAt": 'Дата создания',
    "DateOfIssue": 'Дата выдачи',
    "DateEnding": 'Дата окончания',
    "UpdatedAt": 'Дата обновления',
    "DateOfPayment": 'Дата оплаты',
    "DateConclision": 'Дата заключения',
    "DateOfGettingResult": 'Дата получения результата',

    // под вопросом
    "Id": 'id',
    // неинформативные id
    "TypeId": 'Тип',
    "OrderId": 'Заказ',
    "SampleId": 'Образец',
    "ServiceId": 'Услуга',
    "ContractId": 'Контракт',
    "UserIdCreated": 'Создал',
    "UserIdUpdated": 'Изменил',
  };

  return roleMappings[param]
};

export const translateValue = (value: string): string => {
    if (value === 'True') {
      return 'Да';
    } else if (value === 'False') {
      return 'Нет';
    }
  const dateRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;
  const dateRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;
  if (dateRegex.test(value)) {
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  } else if (dateRegex2.test(value)) {
    return convertFormat(value)
  }

  return value;

}
